@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.form {
  a {
    color: $picton-blue !important;
  }
}

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.button {
  @extend .btn-primary;
}

.input {
  @extend .form-input;
}

.title {
  font-size: 2em;
  @include product-sans;
  font-weight: bold;
}

:global {
  .auth-controls {
    @include product-sans;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

:global {
  .ant-spin-nested-loading > div > .ant-spin {
    min-height: 100vh;
    z-index: 10000;
  }
}

@import '../../../../assets/styles/colors';

.imagesWrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
}
.image{
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
    object-fit: cover;
  cursor: pointer;
}
.previewImage{
  height: 400px;
  width: 100%;
  margin-top: 20px;
  object-fit: cover;
}

.column{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imageList{
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  li{
    width: 150px;
    margin-bottom: 5px;
    margin-right: 10px;
    position: relative;
    &:hover{
      &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 95%;
        top: 0;
        right: 0;
        background: $black;
        opacity: 0.4;
        pointer-events: none;
      }
      .imgOptions{
        visibility: visible;
      }
    }
  }
}
.imgOptions{
  position: absolute;
  display: flex;
  width: 50%;
  justify-content: space-between;
  left: 50%;
  margin-left: -35px;
  top: 50%;
  color: $white;
  font-size: 25px;
  margin-top: - 17.5px;
  cursor: pointer;
  visibility: hidden;
  z-index: 15;
  span{
    &:hover{
      opacity: 0.5;
    }
  }
}
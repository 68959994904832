@import '../../assets/styles/colors';
@import '../../assets/styles/variables';
@import '../../assets/styles/fontMixins';

.landingPage {
  @include open-sans;
  z-index: 12;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: $black;
  background-color: $home-bg-ligth;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: $black;
  }
}

.zeroIndent {
  margin-top: 0;
}

.certificatesBlock {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 75vw;
  left: 11%;
}

.title {
  @include gotham-pro-black;
  position: absolute;
  left: -35%;
  top: 35vw;
  font-size: 5.14285714286vw;
  line-height: 4.8vw;
  width: 20vw;
  font-weight: 800;
  text-align: center;
  transform: rotate(-90deg);
  letter-spacing: 0;
  color: $black;
}

.contactTitle {
  @include gotham-pro-black;
  font-size: 3.125vw;
  width: 30%;
  line-height: 2.98611111111vw;
  color: $black;
}

.contactBlockWhite {
  .contactTitle {
    color: $white;
  }

  p {
    color: $white !important;
  }
}

.contactBlock {
  margin: 10.764vw 0 20vw 2vw;
  width: 52.992vw;

  p {
    margin-top: 0.69444444444vw;
    font-size: 1.111vw;
    line-height: 1.667vw;
    margin-bottom: 2.08vw;
    color: $home-grey;
  }
}

.white {
  color: $white;
}

.withAdditionalText{
  margin-bottom: 3vw;
}

.additionalBlock {
  margin-bottom: 16vw;
}

.additionalText {
  width: 70vw;
  margin-top: 4vw;
  max-width: 57.408vw;
  font-size: 1.104vw;
  line-height: 1.656vw;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #5e5e5e;
  white-space: pre-wrap;
}

@media only screen and (max-width: 760px) {
  .landingPage {
    padding: 0 8.53333333333vw;
  }

  .certificatesBlock {
    width: 100%;
    left: 0;
  }

  .title {
    width: 100%;
    position: initial;
    transform: rotate(0);
    font-size: 9.788359788359788vw;
    line-height: 10.582010582010582vw;
    text-align: left;
    margin-bottom: 0;
  }

  .contactBlock {
    width: 100%;
    height: 100%;

    p {
      margin: 5.291005291005291vw 0;
      font-size: 4.232804232804233vw;
      line-height: 6.613756613756614vw;
    }
  }

  .contactTitle {
    font-size: 11.904761904761905vw;
    line-height: 11.375661375661376vw;
  }

  .additionalText {
    max-width: 98%;
    font-size: 1.1em;
    line-height: 1.5em;
  }
}

@import '../../assets/styles/colors.scss';

.container {
  margin-bottom: 5.5vw;
  max-width: 100vw;

  :global {
    .whiteDots {
      li button:before {
        color: white !important;
      }
    }

    .slick-dots {
      li {
        margin: 0 !important;

        button:before {
          font-size: 0.69vw !important;
          top: 0.69vw !important;
        }
      }
    }

    .slick-arrow {
      top: 21.3vw !important;
      z-index: 10;

      &:before {
        color: $black !important;
      }
    }

    .slick-prev {
      left: 0 !important;
    }

    .slick-next {
      right: 0 !important;
    }

    .whiteArrows {
      &:before {
        color: white !important;
      }
    }
  }
}

.image {
  height: 20vw;
  margin-right: 1vw;
  cursor: pointer;
  object-fit: cover;
  border-radius: 0.556vw;
  margin-bottom: 10px;
  min-height: 150px;
}

.row {
  display: flex;
}

.sliderContainer {
  max-width: 53vw;
  min-widtH: 280px;
}

.arrow {
  color: var(--color) !important;
  background: #ff4d4f;
}


@media screen and (max-width: 768px) {
  .container {
    width: 100vw;
  }
  .image {
    height: 40vw;
  }

  .container {
    :global {
      .slick-slider {
        width: 100vw;
      }

      .slick-arrow {
        display: none !important;
      }

      .whiteDots {
        li button:before {
          font-size: 8px !important;
          top: 8px !important;
        }
      }
    }
  }
}

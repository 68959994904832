.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.center {
  @extend .row;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.wrapper {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

:global {
  .ant-tabs {
    padding: 10px !important;
  }
  .ant-spin-nested-loading {
    width: 100%;
    height: 100%;
  }
}

.icon {
  margin-right: 5px;
}

@media screen and (min-width: 651px){
  .row {
    flex-direction: row;
    justify-content: space-between;
  }
  .center {
    justify-content: center;
  }
  .column {
    width: 50%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 901px) {
  .column {
    width: 65%;
  }
}

@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/forms';

// 1px    =>  0.06944444vw


.header {
  width: 100%;
  height: 32.777776vw;
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;
  object-fit: cover;
  max-height: 100%;
}

.imageWrapper {
  width: 100% !important;
  height: 32.777776vw !important;
  margin: 0!important;
  z-index: 0 !important;
}

.cover {
  background-color: rgba($black, 0.3);
  height: inherit;
  width: 100%;
  position: absolute;
}

.icon {
  font-size: 100px;
  color: rgba($black, 0.65);
  align-self: center;
}

.videoWrapper {
  @extend .imageWrapper;
  object-fit: cover;
}

.topStretchingHeader {
  height: 46.777776vw;
}

.topStretchingImageWrapper {
  height: 46.777776vw !important;
}

@media only screen and (max-width: 550px) {
  .header {
    height: initial;
  }

  .imageWrapper {
    height: 100% !important;

    .image {
      height: 100%;
      max-width: initial;
      max-height: initial;
    }
  }
}

@import "../../assets/styles/colors";
@import '../../assets/styles/fontMixins';

$defaultSpace: 3.47222222222vw;

.navbar {
  @include open-sans;
  padding: $defaultSpace;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 13.8571428571vw;

  span {
    cursor: pointer;
  }
}

.fontWhite {
  span, p {
    color: $white;
  }
}

.logo {
  margin-left: 0;
  width: 5.55555555556vw;
  height: 4.51388888889vw;
}

.links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.item {
  text-transform: uppercase;
  letter-spacing: 0.1vw;
  line-height: 1.42857142857vw;
  font-size: 0.85714285714vw;
  font-weight: 800;
  margin-left: 2vw;
  color: var(--color-var);
  position: relative;
  text-decoration: none;

  a {
    text-decoration: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2vw;
    bottom: -0.4vw;
    left: 0;
    background-color: var(--color-var);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
  }

  &:hover {
    &:after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}

.titleWrapper {
  padding: 0 $defaultSpace;
  margin-bottom: $defaultSpace;
}

.mainTitle {
  @include gotham-pro-black;
  display: inline-block;
  margin: 0 auto;
  font-size: 3.57142857143vw;
  line-height: 3.92857142857vw;
  margin-left: 23%;
  width: 45%;
}

.secondaryTitle {
  @include gotham-pro-black;
  display: none;
}

.headerContent {
  @include open-sans;
  padding: 0 $defaultSpace;
  padding-bottom: 12.1428571429vw;
  display: flex;

  .description {
    margin-top: 7.14285714286vw;
    position: absolute;
    font-size: 1.11111111111vw;
    line-height: 1.78571428571vw;
    color: $home-grey;
    width: 18vw;
  }
}

.fullScreenHeader {
  @extend .headerContent;
  padding: 0;
}

.contentImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  margin-left: 23%;
  min-width: 74.4444444444vw;
  height: 35.7142857143vw;
  z-index: 5;
}

.fullScreenContent {
  height: 49.1666666667vw;
  z-index: 5;
  width: 100vw;
  position: relative;
  bottom: 5vw;
  margin: 0;

  .videoContainer {
    width: 100%;
    height: 49.1666666667vw !important;;
  }
}

.root {
  display: flex;
}

.payment {
  display: none;
}

@media only screen and (max-width: 760px) {
  .logo {
    width: 15vw;
    height: 15vw;
  }

  .fullScreenHeader {
    display: none;
  }

  .fullScreenContent {
    top: 24vw;
  }

  .navbar {
    position: fixed;
    padding: 8.53333333333vw;
    margin-bottom: 70px;
    height: 24vw;
    width: 100%;
    z-index: 10;
  }

  .titleWrapper {
    margin: 0;
    padding: 46.560846560846561vw 0 10.582010582010582vw;
  }

  .mainTitle {
    margin: 0;
    font-size: 9.788359788359788vw;
    width: 100%;
    padding: 0 8.465608465608466vw;
    line-height: 11.64021164021164vw;
  }

  .secondaryTitle {
    margin: 0;
    font-size: 9.788359788359788vw;
    width: 100%;
    padding: 0 8.465608465608466vw;
    line-height: 11.64021164021164vw;
    color: $black;
    display: block;
  }

  .item {
    display: none;
  }

  .headerContent {
    flex-direction: column;
    padding: 0 0 26.455026455026455vw;

    .description {
      width: 100%;
      position: initial;
      margin-top: 10.582010582010582vw;
      font-size: 4.232804232804233vw;
      line-height: 6.613756613756614vw;
      padding: 0 8.465608465608466vw;
    }
  }

  .content {
    margin: 0;
    width: 100vw;
    min-height: 92.592592592592593vw;
  }

  .payment {
    display: flex;

    .root {
      display: none;
    }
  }
}

@import "../../assets/styles/colors";

.wrapper {
  width: 170px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  position: relative;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.cover {
  @extend .wrapper;
  position: absolute;
  z-index: 10;
  background: $white;
}

.remove {
  position: absolute;
  border-radius: 50%;
  background-color: $white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  right: 0;
  top: -10px;
  border: 1px solid rgba(0, 0, 0, 0.65);
  z-index: 2;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
}

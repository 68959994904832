
$mobileContentWidth: 85%;

$mobileTextSize: 1.1em;
$mobileTextLineHeight: 1.5em;

$mobileTitleSize: 1.5em;
$mobileSubTitleSize: 1.375em;

$mobileFooterTextSize: 1em;
$mobileFooterLineHeight: 2em;
.table {
  flex: 1;
  align-self: stretch;
  padding: 15px;
}

.inactive {
  opacity: 0;
  transition: all .3s ease-in-out;
  display: inline;
}

.btn {
  margin-left: 5px;
}

.addBtn {
  margin-bottom: 20px;
}

:global {
  .ant-table-row:hover {
    :local {
      .inactive {
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
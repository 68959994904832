@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/fontMixins.scss';

.pageContainer {
  padding-top: 6vw;

  :global {
    .ant-form-item {
      margin: 0;
    }

    .ant-col .ant-form-item-control {
      position: relative;
    }

    .ant-form-item-explain {
      position: absolute;
      top: 3.3vw;
      left: 0;
      font-size: 0.903vw;
      display: flex;
      align-items: flex-end;
      text-align: left;
      color: $red !important;
    }
  }
}


.buyForm {
  width: 49.652vw;
  margin: 10.138vw auto 0 auto;
}

.formTitle {
  @include gotham-pro-black;
  font-size: 3.125vw;
  line-height: 2.98611111111vw;
  width: 26.5vw;
  margin-bottom: 2.2222222vw;
  color: $black;
}

.input {
  @include open-sans;
  height: 3.333vw;
  background-color: transparent !important;
  border: none;
  margin-bottom: 1.4vw;
  font-size: 0.97222222222vw;
  -webkit-appearance: none;
  border-bottom: 0.208333333333333vw solid $black;
  padding: 0;

  &:hover {
    background: transparent;
    border-bottom-color: var(--color) !important;
  }

  &:focus {
    border-bottom-color: var(--primary-color);
    outline: none !important;
    box-shadow: none !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 0.903vw;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.903vw;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    font-size: 0.903vw;
  }

  &:-moz-placeholder { /* Firefox 18- */
    font-size: 0.903vw;
  }
}

.formFooter {
  @include open-sans;
  display: flex;
  justify-content: flex-end;
  margin-top: 2.083vw;
  margin-bottom: 3.472vw;
  position: relative;

  input {
    display: none;
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: var(--background-color);
    border-color: var(--border-color);
  }
  :global(.ant-checkbox-inner) {
    width: 1.111111111111111vw;
    height: 1.111111111111111vw;
  }
  :global(.ant-checkbox-inner:after) {
    border-color: transparent;
  }
  :global(.ant-checkbox-checked::after) {
    border-color: var(--border-color);
  }
}

.agreement {
  display: flex;
  align-items: flex-start;

  p {
    font-size: 0.7638vw;
    line-height: 1.180vw;
    max-width: 22.5vw;
    opacity: 1;
    cursor: pointer;
    margin-top: -0.3vw;
  }
}

.total {
  font-size: 1.3888vw;
  text-transform: uppercase;
  color: $landing-grey;
  font-weight: 600;

  span {
    text-transform: uppercase;
    margin-right: 8px;
  }

  p {
    display: inline-block;
    margin-right: 0.4vw;
  }
}

.whiteButton {
  color: $white;
}

.certificateOptions {
  @include open-sans;
  display: flex;
  margin-top: 2.222vw;
  justify-content: space-between;
}

.optionsLabel {
  font-size: 1.1111vw;
  line-height: 1.736vw;
  width: 12.5vw;
  min-width: 12.5vw;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 1.38vw;;
}

.quantityLabel {
  @extend .optionsLabel;
  min-width: 10.83vw;
  width: 10.83vw;
}

.certificateType {
  display: flex;
}

.certificateQuantity {
  display: flex;
}

.quantityInput {
  font-size: 0.902vw;
  display: flex;
  height: 2.6388vw;
  min-width: 9.375vw;
  justify-content: space-between;
  border: 0.208333333333333vw solid $black;
  align-items: center;
  padding: 0 0.83vw;

  span {
    cursor: pointer;
    font-size: 1.111vw;
    color: $black;
  }
}

.agreementCheckbox {
  margin-right: 0.97vw;
  margin-top: 0.3vw;
}

.rule {
  cursor: pointer !important;

  a {
    margin: 0 6px;
    position: relative;

    &:hover {
      text-decoration: underline;
    }
  }
}

.button {
  @include open-sans;
  padding: 1.527vw 3.75vw;
  font-size: 0.833vw;
  height: 4.16666666667vw;
  text-transform: uppercase;
  letter-spacing: 0.15vw;
  cursor: pointer;
  font-weight: 700;
  border: 1px transparent;
}

.whiteFonts {
  h3, p, span, input, button, a, label, input {
    color: $white !important;
    opacity: 1 !important;
  }

  .quantityInput {
    border: 0.208333333333333vw solid $white;
  }

  .input {
    border-bottom: 0.208333333333333vw solid $white;
    color: $white !important;
  }
}

.rules {
  position: absolute;
  left: 0;

  :global {
    .ant-form-item-explain {
      @include product-sans;
      top: 2.5vw;
    }
  }
}

.arrowSvg {
  &:hover {
    color: var(--color) !important;
  }
}

@media screen and (max-width: 760px) {
  .pageContainer {
    padding: 0;
    overflow: hidden;

    :global {
      .ant-form-item-explain {
        font-size: 3.3vw !important;
        bottom: 1.4vw !important;
        top: 4.8vw;
      }
    }
  }

  .agreementCheckbox {
    margin-right: 8px;
  }

  .formTitle {
    font-size: 11.904761904761905vw;
    line-height: 11.375661375661376vw;
    width: 100%;
    margin-bottom: 7.936507936507937vw;
  }

  .buyForm {
    width: 100%;
    padding: 0 8.53333333333vw;
    margin-top: 26.455026455026455vw;
  }

  .certificateOptions {
    flex-direction: column;
  }

  .optionsLabel {
    font-size: 3.439153439153439vw;
    line-height: 5.291005291005291vw;
    width: 100%;
  }

  .certificateType,
  .certificateQuantity {
    margin-bottom: 6.349206349206349vw;
  }

  .quantityInput {
    height: 10.582010582010582vw;
    width: 35.714285714285714vw;
    font-size: 3.174603174603175vw;
    padding: 0 3.968253968253968vw;
    border: 0.793650793650794vw solid $black;

    span {
      cursor: pointer;
      font-size: 3.439153439153439vw;
    }
  }

  .rules {
    position: relative;

    :global {
      .ant-form-item-explain {
        top: 8.5vw !important;
      }
    }
  }

  .formFooter {
    flex-direction: column-reverse;
    margin-bottom: 13.227513227513228vw;

    :global(.ant-checkbox-checked .ant-checkbox-inner) {
      background-color: var(--background-color);
      border-color: var(--border-color);
    }
    :global(.ant-checkbox-inner) {
      width: 4.5vw;
      height: 4.5vw;
    }

    .agreementCheckbox {
      margin-top: 0.9vw;
      margin-right: 2.116402116402116vw;
    }

    .agreement {
      margin-top: 0;

      p {
        max-width: 100%;
        font-size: 2.645502645502646vw;
        line-height: 3.703703703703704vw;
      }

      a {
        margin: 0 4px;
      }
    }
  }

  .total {
    font-size: 5.291005291005291vw !important;
    line-height: 6.613756613756614vw;
    margin-bottom: 7.936507936507937vw;

    p {
      margin-bottom: 0;
    }
  }

  .input {
    width: 100%;
    height: 3em;
    font-size: 3.1vw;
    margin-bottom: 8vw;
    color: $black;
    border: none !important;
    border-bottom: 0.793650793650794vw solid $black !important;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 3.703703703703704vw;
      line-height: 5.026455026455026vw;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      line-height: 5.026455026455026vw;
      font-size: 3.703703703703704vw;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 3.703703703703704vw;
      line-height: 5.026455026455026vw;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: 3.703703703703704vw;
      line-height: 5.026455026455026vw;
    }
  }

  .button {
    min-width: 53.33vw;
    min-height: 16vw;
    padding: 5.866vw 14.4vw;

    span {
      font-size: 3.174603174603175vw;
      line-height: 4.322751322751323vw;
      letter-spacing: 0.2em;
    }
  }

  .whiteFonts {
    .quantityInput {
      border: 0.79365vw solid $white;
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

div.dragger {
  width: 90%;
  margin: 0 auto;
}

div.image {
  width: 90% !important;
  height: auto;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;
}

.modal {
  min-height: 300px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
}

.tip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 31px;
  }

  p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1151px) {
  .wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .dragger {
    min-width: 240px !important;
    margin: initial;
  }

  div.image {
    margin-left: 10px;
    width: 170px !important;
    height: 100px;
    margin-top: 0;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/fontMixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.611vw 5.9vw;
  background: $home-footer;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 1.111vw;
  line-height: 1.667vw;
  width: 100vw;
  min-height: 16.422vw;
  @include product-sans-light;

  a {
    color: $black;
    &:hover {
      text-decoration: underline;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  width: 45%;
}

.address {
  min-width: 25vw;

  & > div {
    margin: 2px 0;
  }
}

.logo {
  margin-right: 3.312vw;
  max-width: 14vh;
  height: 5.589vw;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.right {
  width: 55%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.terms {
  display: flex;
  justify-content: center;
  align-items: center;

  .termsContent {
    display: flex;
    flex-direction: column;
  }
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .socialIcon {
    margin: 0 1.104vw;
    width: 2.967vw;
    height: 2.967vw;
    transition: .3s;
  }
}

.white {
  color: rgba(255, 255, 255, 1) !important;
  &:hover {
    color: rgba(255, 255, 255, 0.8)!important;
  }
  a {
    color: rgba(255, 255, 255, 1);
    &:hover {
      color: rgba(255, 255, 255, 0.8)!important;
    }
  }
}

.dark {
  color: rgba(0, 0, 0, 1) !important;
  &:hover {
    color: rgba(0, 0, 0, 0.8)!important;
  }
  a {
    color: rgba(0, 0, 0, 1) !important;
    &:hover {
      color: rgba(0, 0, 0, 0.8)!important;
    }
  }
}

.lightGrey {
  fill: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);

  &:hover {
      fill: rgba(255, 255, 255, 0.8);
      color: rgba(255, 255, 255, 0.8);
  }
}

.darkGrey {
  fill: rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);

  &:hover {
      fill: rgba(0, 0, 0, 0.8);
      color: rgba(0, 0, 0, 0.8);
  }
}

.disabled {
  pointer-events: none;
}

.restriction {
  max-width: 84%;
}

.payments {
  display: flex;
}

.paymentIcon {
  margin-right: 0.625vw;
  fill: #656461;
  width: 2.967vw !important;
  height: 2.967vw;
}


@media only screen and (max-width: 550px) {
  .footer {
    flex-direction: column;
    padding: 2.5em 0;
    font-size: 16px;
    line-height: 32px;
  }

  .info {
    width: 95vw;
    margin: 0.694vw auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .logo {
      height: 5em;
      margin-bottom: 1em;
      margin-right: 0;
      max-width: 50vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .address {
    text-align: center;
    font-size: $mobileFooterTextSize;
    line-height: $mobileFooterLineHeight;
  }

  .restriction {
    max-width: 100%;
  }

  .terms {
    width: 95%;
    margin: 0.694vw auto;
  }

  .social {
    width: 95%;
    margin: 1em auto;
    justify-content: center;

    a {
      margin: 0 1.556vw;
    }

    .socialIcon {
      width: 3em;
      height: 3em;
    }
  }

  .payments {
    justify-content: center;
  }

  .paymentIcon {
    width: 2em !important;
    height: 2em;
  }

  .right {
    flex-direction: column;
  }

  .terms {
    text-align: center;
  }

  .termsContent {
    a {
      font-size: $mobileFooterTextSize;
      line-height: $mobileFooterLineHeight;
    }
  }
}

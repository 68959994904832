@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';

.faqMain{
  @include product-sans;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8vw;
}
.title{
  text-align: center;
}

.mainText{
  font-style: normal;
  font-weight: 700;
  font-size: 2.57vw;
  line-height: 3.472vw;
  text-align: center;
  color: $home-dark;
  margin-bottom: 3.5vh;
}

.secondaryText{
  font-weight: bold;
  font-style: normal;
  font-size: 1.389vw;
  color: $home-grey;
  line-height: 2.1vw;
}

.titleContacts{
  @extend .secondaryText;

  a{
    color: $home-light-green;
  }
}

.waveImage{
  position: absolute;
  top: 0;
  right: 0;
  max-width: 36%;
  z-index: 1;
}
.faqContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6vh;
}

.contentTitle{
  @extend .secondaryText;
}

.collapseMain{
  margin-top: 4vh;
}
.collapseText{
  font-weight: lighter;
}

.collapseItem{
  font-size: 1.25vw;
  color: $home-grey;
  font-weight: bold;
  margin-bottom:0;
  text-align: left;
  background: $white;
  border-bottom: 1px solid rgba($home-dark, .1);
  padding: 1vh 0;
  width: 46.875vw; /*900px*/
}

@media only screen and (max-width: 550px) {
  .title{
    margin-top:4vh;
  }
  .titleContacts{
    a{
      display: block;
    }
  }
  .mainText{
    font-size: 30px;
    line-height: 40px;
  }

  .secondaryText{
    font-size: 15px;
    line-height: 25px;
  }

  .faqContent{
    margin-top:6vh;
  }

  .collapseItem{
    width: 80vw;
    font-size: 15px;
  }
}

@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';

:global {
  .ant-select-item-option-content {
    @include product-sans;
    text-align: center;
  }
}

.buyForm {
  margin-bottom: 7.935vw;
  width: 52.992vw;
  min-height: 37.467vw;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.333vw 0;
  border-radius: 0.556vw;

  h4 {
    margin-top: 3.312vw;
    margin-bottom: 0;
  }

  p {
    max-width: 29.67vw;
    text-align: center;
    margin-top: 0.552vw;
    font-size: 1.111vw;
    line-height: 1.667vw;
    margin-bottom: 3.333vw;
  }

  :global {
    .ant-form-item {
      margin: 0 !important;
    }

    .ant-col .ant-form-item-control {
      position: relative;
    }

    .ant-form-item-explain {
      position: absolute;
      bottom: 0.556vw;
      left: 0;
      font-size: 0.903vw;
      color: $red-1;
      display: flex;
      align-items: flex-end;
    }

    .ant-input .ant-form-item-has-error {
      border-color: $red-1 !important;
    }
  }

  div.button {
    text-align: center;
    margin-top: 1vw !important;
  }

  .buttonElement {
    font-size: 1.667vw;
    line-height: 2.153vw;
    font-style: normal;
    font-weight: bold;
    width: 17.319vw;
    height: 4.899vw;
    border-radius: 0.556vw;
    border: none;
    transition: .3s;

    &:hover {
      filter: brightness(1.1);
    }

    &:focus {
      border: none;
    }
  }
}

.input {
  border-radius: 0.556vw;
  width: 42.159vw;
  height: 3.333vw;
  background: $white;
  border: 0.069vw solid rgba(94, 94, 94, 0.4);
  margin-bottom: 2.222vw;
  font-size: 0.903vw;
  resize: none;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 0.903vw;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.903vw;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    font-size: 0.903vw;
  }

  &:-moz-placeholder { /* Firefox 18- */
    font-size: 0.903vw;
  }
}

.formField {
  width: 42.159vw;
  height: 2.333vw;
  background: $white;
  margin-bottom: 2.222vw;
  font-size: 0.903vw !important;

  span {
    input, span {
      width: 0.903vw !important;
      height: 0.903vw !important;
    }
  }
}

.additionalWrapper {
  display: flex;
  align-items: center;
  margin-top: 14px;
  width: 42.159vw;
}

.label {
  font-size: 1.736vw;
  line-height: 2.222vw;
  color: $home-grey;
  margin: 0 1.667vw 0 0;
  white-space: nowrap;
}

.qualityInput {
  @extend .input;
  margin: 0;
  width: 3.8vw !important;
}

.selectInput {
  @extend .input;
  margin: 0;
  width: 10vw !important;
  overflow: hidden;

  & > div {
    text-align: center;
    border: none !important;
    border-radius: inherit !important;
    height: 3.333vw !important;

    & > span {
      height: 3.333vw !important;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        height: 3.333vw !important;
      }
    }
  }
}

.additionalInfo {
  & > div {
    & > div {
      & > div {
        & > div {
          margin-top: 3vw;
        }
      }
    }
  }
}

.number {
  font-size: 1.389vw;
  line-height: 1.736vw;
  font-style: normal;
  font-weight: bold;
  color: $black;
  width: 1.736vw;
}

.arrows {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.111vw;
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.111vw;
  height: 1.111vw;
  cursor: pointer;

  img {
    transition: transform .2s;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

.up {
  @extend .block;
  top: 0;
}

.down {
  @extend .block;
  bottom: 0;
}

.wrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 550px) {
  .buyForm {
    width: 90vw;
    border-radius: 8px;

    :global {
      .ant-form-item-explain {
        width: 80vw;
        font-size: 2.3vw;
      }
    }

    .buttonElement {
      font-size: 1.1em;
      line-height: 1.5em;
      font-style: normal;
      font-weight: bold;
      width: 80vw;
      height: 3em;
      margin-top: 2vw;
      border-radius: 0.556vw;
      border: none;

      &:focus {
        border: none;
      }
    }
  }
  .input {
    width: 79vw;
    height: 3em;
    font-size: 1.1em;
    margin-bottom: 4.5vw;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 1.1em;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      font-size: 1.1em;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 1.1em;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: 1.1em;
    }
  }

  .label {
    font-size: 1.1em;
    margin-bottom: 4.5vw;
  }

  .selectInput {
    width: 79vw !important;
    margin-bottom: 0;

    & > div {
      height: 3em !important;
      align-items: center;
    }
  }

  .additionalWrapper {
    width: 79vw;
    font-size: 1.1em;
    margin-bottom: 3vw;
    flex-direction: column;
    align-items: flex-start;

    :global {
      .ant-form-item-explain {
        bottom: -4vw;
      }
    }
  }

  .formField {
    width: 80vw;
    font-size: 1.1em !important;
    margin-bottom: 2vw;

    span {
      input, span {
        width: 4vw !important;
        height: 4vw !important;
      }
    }
  }
}

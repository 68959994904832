@import './fonts';
@import './forms';
@import './icons';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

body {
  :global {
    div.container {
      min-height: 100%;
      overflow: hidden;
    }
  }

  .ant-btn-primary {
    background-color: $picton-blue;
    border-color: $picton-blue;
    &:hover {
      background-color: $picton-blue;
      border-color: $picton-blue;
    }
  }

  .ant-pagination-item-active {
    border-color: $picton-blue;
    color: $picton-blue;
  }
  .ant-pagination-item-active a {
    color: $picton-blue;
  }

  .ant-pagination-item:hover, .ant-pagination-item:focus {
    border-color: $picton-blue;
    color: $picton-blue;
  }

  .ant-pagination-item:hover a, .ant-pagination-item:focus a {
    color: $picton-blue;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    &:focus,
    &:hover {
      border-color: $picton-blue !important;
      color: $picton-blue !important;

      a:hover {
        color: $picton-blue;
        border-color: $picton-blue !important;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $picton-blue;
    border-color: $picton-blue;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $picton-blue;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: inherit;
  }
}

@mixin table {
  @media screen and (max-width: 1200px) {
    .ant-table-content {
      table, tbody, tr, td {
        display: block;
      }
    }
  }
}

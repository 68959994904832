@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';

.page {
  @include product-sans;
  z-index: 12;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: $black;
  background-color: $home-bg-ligth;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    color: $black;
  }
}

.zeroIndent {
  margin-top: 0;
}

.block {
  margin: 0 auto 2.691vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20vw;
}

.title {
  max-width: 57.408vw;
  font-size: 2.15625vw;
  line-height: 2.76vw;
  font-weight: 800;
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amount {
  font-size: 6vw;
}

.white {
  color: $white;
}

@media only screen and (max-width: 550px) {
  .page {
    margin-top: 0;
  }

  .title {
    font-size: 4.85625vw;
    line-height: 9vw;
  }

  .amount {
    font-size: 10vw;
  }
}

@import "../../assets/styles/colors";
@import "../../assets/styles/variables";
@import "../../assets/styles/fontMixins";

.footer {
  @include open-sans;
  position: relative;
  align-items: center;
  padding: 3.47222222222vw;
  font-style: normal;
  font-weight: normal;
  font-size: 1.111vw;
  line-height: 1.667vw;
  width: 100vw;
  min-height: 40.2777777778vw;
}

.logo {
  position: absolute;
  width: 5.55555555556vw;
  height: 4.51388888889vw;

  img {
    max-width: 100%;
  }
}

.underline {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.2vw;
    bottom: -0.4vw;
    left: 0;
    background-color: var(--color-var);
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
  }

  &:hover {
    &:after {
      transform-origin: bottom left;
      transform: scaleX(1);
    }
  }
}

.navigation {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 9.72222222222vw;
  font-weight: 800;
  letter-spacing: 0.2em;
  font-size: 0.83333333333vw;
  text-transform: uppercase;

  & > div {
    margin-right: 4.5vw;
  }
}

.topLink {
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  color: var(--color-var);
  @extend .underline;
}

.contacts {
  display: flex;
  justify-content: space-between;
  margin-left: 22%;
  margin-bottom: 8.88888888889vw;
  width: 68%;
}

.link {
  position: relative;
  display: block;
  color: var(--color-var);
  margin-bottom: 0.69444444444vw;
  font-size: 1.1111111vw;
  text-decoration: none;
  @extend .underline;
}

.contactBlock {
  span {
    position: relative;
    display: block;
    color: var(--color-var);
    margin-bottom: 0.69444444444vw;
    text-decoration: none;
    font-size: 1.1111111vw;
  }
}

.blockHeader {
  font-weight: 700;
  font-size: 1.1111111vw;
  margin-bottom: 1.73611111111vw;
}

.policy {
  display: flex;
  margin-left: 22%;
  width: 64%;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 1.1111111vw;
  font-weight: 400;

  a {
    margin-left: 0.3vw;
  }
}

.powered {
  display: flex;
}

.footerWhite {
  a, p, span {
    color: $white;
  }

  .policyBlock {
    a {
      color: $white;
    }
  }
}

.policyBlock {
  a {
    display: block;
    word-break: keep-all;
  }
}

@media screen and (max-width: 760px) {
  .underline {
    &:after {
      height: 0.5vw;
    }
  }
  .footer {
    padding: 13.3vw 8.53333333333vw;
  }

  .logo {
    width: 15vw;
    height: 15vw;
  }

  .navigation {
    margin: 0;
    margin-bottom: 15vw;
    min-height: 11.111111111111111vw;

    div, span {
      display: none;
    }
  }

  .contacts {
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
  }

  .contactBlock,
  .policyBlock,
  .powered {
    p {
      font-weight: 700;
      font-size: 4.232804232804233vw;
      line-height: 6.613756613756614vw;
      margin-bottom: 1.73611111111vw;
    }

    span, a {
      font-size: 4.232804232804233vw;
      line-height: 6.613756613756614vw;
    }
  }

  .contactBlock {
    margin: 0 2.645502645502646vw 10.582010582010582vw 0;
  }

  .policyBlock {
    width: 100%;
    margin-bottom: 10.582010582010582vw;
  }

  .policy {
    flex-direction: column-reverse;
    align-items: flex-start;
    font-size: 4.232804232804233vw;
    line-height: 6.613756613756614vw;
    width: 100%;
    margin-left: 0;

    a {
      margin-left: 1vw;
    }
  }
}

@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fontMixins.scss';

.select {
  position: relative;
  display: flex;
  font-size: 0.833333333vw;
  height: 2.6388vw;
  min-width: 9.375vw;
  justify-content: space-between;
  border: 0.208333333333333vw solid $black;
  align-items: center;
  text-transform: uppercase;
  z-index: 10;
  padding: 0.763888888888889vw 0 0.763888888888889vw 1.04166667vw;
  cursor: pointer;
  color: $black
}

.list {
  position: absolute;
  top: 100%;
  display: flex;
  width: calc(100% + 0.416666666666667vw);
  flex-direction: column;
  left: -0.208333333333333vw;
  border: 0.208333333333333vw solid $black;
  padding: 0.277777778vw 0 0.277777778vw 1.04166667vw;
  margin: 0;
  height: 3.958333333333333vw;
  justify-content: space-around;
}

span.item {
  color: #AEAEAE !important;

  &:hover {
    color: var(--font-color) !important;
  }
}

span.selected {
  color: var(--primary-color) !important;
}

.arrow {
  display: block;
  position: absolute;
  top: 50%;
  right: 1.041666666666667vw;

  &:before,
  &:after {
    border-right: 2px solid;
    content: '';
    display: block;
    height: 0.555555555555556vw;
    margin-top: -0.416666666666667vw;
    position: absolute;
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 50%;
    width: 0;
  }

  &:after {
    margin-top: -1px;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.up {
  transform: rotate(270deg);
}

.down {
  transform: rotate(90deg);
}

@media screen and (max-width: 760px) {
  .select {
    height: 10.582010582010582vw;
    width: 35.714285714285714vw;
    font-size: 3.174603174603175vw;
    padding: 0 3.968253968253968vw;
    border: 0.793650793650794vw solid $black;
  }

  .list {
    border: 0.793650793650794vw solid $black;
    height: 17.682010582010582vw;
    left: -0.793650793650794vw;
    padding: 1.368253968253968vw 3.968253968253968vw;
    width: calc(100% + 1.587301587301587vw);
  }

  .arrow {
    right: 3.041667vw;

    &:before,
    &:after {
      height: 2.155556vw;
      border-right: 0.530503978779841vw solid;
    }

    &:after {
      margin-top: 0.800952vw;
    }
  }

  .up {
    right: 5.441667vw;
  }
}

@import '../../../assets/styles/colors';
@import '../../../assets/styles/fontMixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: 1.28571428571vw;
  width: 23.5416666667vw;
  margin-bottom: 6.94444444444vw;

  a:hover {
    color: inherit;
  }
}

.invisible {
  display: none;
}

.whiteFont {
  .cardHeader, p, span {
    color: $white;
  }
}

.discountImg {
  width: 80%;
  position: relative;

  svg {
    position: relative;
    width: 100%;
    height: 100%;
    right: 5%;
    bottom: 0.3vw;
  }

}

.imgWrapper {
  width: 100%;
  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.4s;
  width: 100%;
  color: $black;
  position: relative;
  min-height: 53.8194444444vw;
}

.cardImage {
  height: 14.2857142857vw;
  background: #EFEFEF;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img,
  svg {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.discountWrapper {
  width: 50%;
  position: absolute;
  height: 100%;
  z-index: 2;
}

.discountWhite {
  color: $white !important;

}

.cardHeader {
  font-weight: 700;
  font-size: 1.38888888889vw;
  margin-bottom: 1.42857142857vw;
  display: flex;
  justify-content: space-between;
}

.orderNum {
  @include gotham-pro-black;
  font-size: 3.21428571429vw;
  margin-bottom: 8.92857142857vw;
  transition: .3s;
}

.validPeriod {
  font-weight: 400;
  font-size: 1vw;
  color: $home-grey;
  margin: 0.71428571428vw 0;
}

.cardPrice {
  font-size: 1.07142857143vw;
  font-weight: 400;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 0.2vw;
}

.discount {
  margin-right: 0.49444444444vw;
  font-size: 0.85714285714vw;
  color: $light-grey;
  text-decoration: line-through;
}

.preview {
  cursor: default;

  &:hover {
    box-shadow: none;
  }
}

.imageHover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.plus {
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  display: block;
  font-size: 3.4vw;
  z-index: 3;
}

.discountPrice {
  position: absolute;
  transform: rotate(-45deg);
  font-size: 0.69444444444vw;
  font-weight: 700;
  width: 100%;
  letter-spacing: 0.3em;
  line-height: 0.9vw;
  color: $black;
  right: 1.1vw;
  z-index: 20;
  top: 1.5vw;
}

.orderButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.71428571428vw 4.10714285714vw;
  color: $black;
  cursor: pointer;
  font-size: 0.85714285714vw;
  font-weight: 600;
  line-height: 1.78571428571vw;
  transition: .3s;
  border: 0.13888888888888vw solid $black;
  letter-spacing: 0.35vw;
  text-transform: uppercase;
  text-decoration: none;
}

.whiteFontButton {
  color: $white;
}

.title {
  font-size: 1.389vw;
  line-height: 1.736vw;
  font-weight: bold;
}

.description {
  min-height: 19.0972222222vw;
  font-size: 1.11111111111vw;
  line-height: 1.78571428571vw;
  font-weight: 400;
  font-style: normal;
  color: $black;
}

.white {
  color: $white;

  &:before {
    color: $white;
  }
}

.commencementDate {
  font-size: 0.9rem;
}

.oldPrice {
  position: relative;
  font-size: 1.2vw;
  color: $black;

  &:before {
    display: block;
    height: 0.07vw;
    content: '';
    width: 100%;
    background: $black;
    position: absolute;
    top: 50%;
    left: 0;
  }
}

@media only screen and (max-width: 760px) {
  .plus {
    font-size: 13.227513227513228vw;
  }

  .discountPrice {
    font-size: 2.6vw;
    top: 7vw;
    right: 3vw;
  }

  .orderNum {
    font-size: 11.904761904761905vw;
    margin-bottom: 7.936507936507937vw;
    margin-top: 10.582010582010582vw;
  }

  .cardImage {
    width: 100%;
    min-height: 52.91005291005291vw;
    margin-bottom: 2.645502645502646vw;
  }

  .cardHeader {
    font-size: 5.291005291005291vw;
    line-height: 7.142857142857143vw;

    span {
      margin-bottom: 6.878306878306878vw;
    }
  }

  .wrapper {
    margin: 0 1.322751322751323vw 10.582010582010582vw 0;
    width: 83.2vw;
  }

  .cardPrice {
    justify-content: flex-end;
    margin-top: 1.2vw;

    span {
      min-width: 9.259259259259259vw;
      font-size: 4vw;
      line-height: 5.390501319261214vw;
    }

    .discount {
      font-size: 3.2vw;
      line-height: 4.312169312169312vw;
    }
  }

  .validPeriod {
    font-size: 3.703703703703704vw;
    line-height: 5.026455026455026vw;
    margin-bottom: 2.91005291005291vw;
  }

  .description {
    font-size: 4.232804232804233vw;
    line-height: 6.613756613756614vw;
    margin-bottom: 10.610079575596817vw;
  }

  .orderButton {
    font-size: 3.174603174603175vw;
    line-height: 6.578947368421053vw;
    letter-spacing: 0.5em;
    padding: 2.6vw 0;
    height: 11.904761904761905vw;
    border: 0.529100529100529vw solid $black;
  }
}


@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';

.buyCertificatePage {
  @include product-sans;
  z-index: 12;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: $black;
  background-color: $home-bg-ligth;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: $black;
  }
}

.galleryTitle {
  width: 53vw;
}

.galleryTitleWhite {
  color: $white !important;
}

.zeroIndent {
  margin-top: 0;
}

@media only screen and (max-width: 550px) {
  .buyCertificatePage {
    margin-top: 0;
    font-size: 16px;
  }
}

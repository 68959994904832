@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/global';

.withoutData {
  margin: 40px;
}

.link {
  color: $picton-blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: 100%;
  text-align: center;
  padding-top: 2rem;
  position: relative;
  button{
    background: $picton-blue;
    color: $white;
  }
  h3 {
    @include product-sans;
    margin-top: 20px;
    text-transform: uppercase;
  }
}
.liqpayHidden{
  display: none;
}

.success {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 100px;
}
.paymentLink{
  margin: 15px auto;
  color: $black;
  font-size: 20px;
  &:hover{
    color: $black;
    text-decoration: underline;
  }
}

.button {
  width: 100%;
}
.publishInfo{
  font-size: 1.5vw;
  margin-bottom: 0.5vw;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 2vw;
}

.list {
  display: flex;
  flex-direction: column;
  max-height: 15vw;
  min-width: 20vw;
  overflow: auto;
}

.row {
  display: flex;
  align-items: center;
  font-size: 1.2vw;
  justify-content: space-between;
  margin: 3px 10px;
}

.copy {
  font-size: 1.2vw;
  cursor: pointer;
}

.subTitle {
  font-size: 20px;
  margin-bottom: 1.5vw;
  color: $black;
}

@media screen and (max-width: 768px){
  .container {
    .withData {
      :global {
        @include table;
        .ant-table-thead {
          display: none;
        }

        td.ant-table-cell {
          border: none;
          border-bottom: 1px solid #eee;
          position: relative !important;
          padding-left: 50% !important;
        }

        .ant-table-cell:before {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }
        .ant-table-cell:nth-of-type(1):before { content: "№"; }
        .ant-table-cell:nth-of-type(2):before { content: "Назва кампанії"; }
        .ant-table-cell:nth-of-type(3):before { content: "Адреса"; }
        .ant-table-cell:nth-of-type(4):before { content: "Копіювати"; }
      }
    }
  }
  .publishInfo{
      font-size: 14px;
  }
  h3{
    font-size: 20px;
  }

  .column {
    a {
      margin-top: 15px;
      font-size: 14px;
    }
  }

  .subTitle{
    font-size: 14px;
  }
  .table{
    width: 85vw;
  }
  .copy {
    font-size: 12px;
  }

}
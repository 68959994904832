.agreement {
  font-size: 16px;
  padding: 5% 0;
  margin: 0 auto;
  width: 70%;

  b {
    font-weight: bold;
    color: #000;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }


  th {
    font-size: 1.2em;
    color: #000;

    &:first-child {
      width: 30%;
    }
  }

  td:first-child {
    color: #000;
  }

  td, th {
    padding: 1em 0.5em;
    vertical-align: top;
  }

}
.wrapper {
  display: flex;
  align-items: center;

  & > div {
    display: flex !important;

    & > span {
      width: 100px;
      height: 40px;
    }
  }
}

.notification {
  color: #515151;
  font-size: 11px;
  margin-left: 10px;
  min-width: 60px;
}

@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';

.successCertificatePage {
  @include product-sans;
  z-index: 12;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: $black;
  background-color: $home-bg-ligth;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 71.967vw;
  h3 {
    color: $black;
  }
}

.zeroIndent {
  margin-top: 0;
}

.infoBlock {
  min-height: 20.424vw;

  img {
    margin-top: 10.212vw;
    height: 9.522vw;
  }

  h3 {
    margin-top: 2.622vw;
  }
}

.message {
  color: $home-grey;
  max-width: 45.54vw;
  margin: 0 auto;

  p {
    font-size: 1.189vw;
    text-align: center;
  }

  .bold {
    font-size: 1.389vw;
    font-weight: bold;
  }
}

.white {
  color: $white;
}

.button {
  font-size: 1.267vw;
  line-height: 2.153vw;
  font-style: normal;
  font-weight: bold;
  height: 3.899vw;
  border-radius: 0.556vw;
  border: none;
  margin-top: 4vw;

  &:focus {
    border: none;
  }

  &:hover {
    filter: brightness(1.1);
  }
}

@media only screen and (max-width: 550px) {
  .successCertificatePage {
    margin-top: 0;
  }

  .infoBlock {
    max-width: 90vw;

    img {
      height: 12.865vh;
      margin: 1.2em 0;
    }

    h3 {
      font-size: 1.5em;
      line-height: 1.5em;
    }
  }

  .message {
    max-width: 90vw;

    p {
      font-size: 1.1em;
      line-height: 1.5em;
    }

    .bold {
      font-size: 1.1em;
    }
  }

  .button {
    font-size: 1.1em;
    line-height: 1.5em;
    font-style: normal;
    font-weight: bold;
    width: 80vw;
    height: 3em;
    border-radius: 0.556vw;
    border: none;
    margin-top: 10vw;
    margin-bottom: 20vw;
  }
}

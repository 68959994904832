$white:                     #FFFFFF;
$black:                     #000000;
$curious-blue:              #20A5CE;
$picton-blue:               #79BBBF;
$picton-blue-rgba:          rgba(121, 187, 191, 1);
$picton-blue-rgba-light:    rgba(121, 187, 191, 0.8);
$mercury:                   #E8E8E8;
$elephant:                  #0F3249;
$alabaster:                 #f0f0f0;
$cerulean:                  #EDEDED;
$gallery:                   #EAEAEA;
$red:                       #ff4d4f;
$red-1:                     #E6737B;
$green:                     #027f2c;
$yellow:                    #FFFF00;
$light-grey:                #BEBEBE;
$light-grey:                #BEBEBE;

$home-dark:                 #07090E;
$home-grey:                 #5E5E5E;
$home-bg-ligth:             #F6F9FE;
$home-light-green:          #48B6BB;
$home-footer:               #2E7C81;
$home-light-brown:          #666666;
$home-grey-second:          #424242;
$landing-grey:              #828547;
$landing-light-grey:        #CACACA;
$charcoal:                  #454545;


@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';

.container {
  width: 90%;
  padding-top: 3rem;
  text-align: center;
  margin-bottom: 3em;
}

.formWrapper {
  max-width: 700px;
  margin: 3rem auto;
}

.column {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 100px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 1rem;
}

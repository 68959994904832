@import '../../../assets/styles/colors';

.infoContainer {
  position: relative;
  height: 20vw;
}

.wrapper {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  background: $black;
  width: 100%;
  cursor: pointer;
}

.row {
  display: flex;
  justify-content: space-between;
  min-height: 8.68055555556vw;
  padding: 3.47222222222vw;
  align-items: center;

  span {
    font-size: 0.83333333333vw;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    cursor: pointer;
    color: $white;
    font-weight: 800;

    &:hover {
      cursor: pointer;
    }
  }
}

.vectorOpen {
  transition: .3s;
  transform: rotate(180deg);
}

.vectorClose {
  transition: .3s;
}

.about {
  max-height: 0;
  height: auto;
  transition: max-height 1s;
  font-size: 1.111vw;
  color: $white;
  padding: 0;
  overflow: hidden;

  .aboutContainer {
    padding: 3.47222222222vw;
    padding-top: 0;
    display: block;
  }
}

.visible {
  max-height: 600px;
}

.white {
  color: $white !important;
}

@media only screen and (max-width: 760px) {
  .infoContainer {
    height: 52.91005291005291vw;
  }

  .row {
    height: 33.068783068783069vw;
    padding: 9.283819628647215vw 13.227513227513228vw;
  }

  .visible {
    max-height: 264.55026455026455vw;
    overflow: auto;
  }

  .wrapper {
    span {
      font-size: 4.232804232804233vw;
    }
  }

}


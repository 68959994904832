@import '../../../assets/styles/colors.scss';

.wrapper {
  display: flex;
  align-items: center;
}

.block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.111vw;
  height: 1.111vw;
  cursor: pointer;

  img {
    transition: transform .2s;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

.price {
  font-size: 2.153vw;
  font-weight: bold;
  color: $black;
  margin: 0 0 0 0.667vw;
  width: 9vw;
  white-space: nowrap;
}

.arrows {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.111vw;
}

.up {
  @extend .block;
  top: 0;
}
.down {
  @extend .block;
  bottom: 0;
}

.number {
  font-size: 1.389vw;
  line-height: 1.736vw;
  font-style: normal;
  font-weight: bold;
  color: $black;
  width: 1.736vw;
}

.quantity {
  display: none;
  font-size: 16px;
}

@media only screen and (max-width: 550px) {
  .quantity {
    display: flex;
    flex-direction: row;

    .number {
      width: 15vw;
      text-align: center;
      font-size: 1.1em;
      line-height: 2.5em;
    }
  }

  .wrapper {
    display: none;
  }

  .price {
    font-size: 1.11em;
    line-height: 2.5em;
    margin-left: 2em;
    width: auto;
  }

}

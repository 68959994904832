@import '../../../assets/styles/fontMixins';
@import '../../../assets/styles/colors';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;

  button {
    margin-right: 10px;
  }
}

.header {
  @include product-sans;
  margin-bottom: -9px;
}

.search {
  width: 200px;
}

.exportCertificatesModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  min-width: 200px;
}

.modalButton {
  width: 40%;
  margin-right: 0 !important;
  margin-top: 30px;
  font-size: 16px;
  background: $picton-blue;
  color: $white;
  border: 1px solid $picton-blue;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $white;
  }
}

.createCertificate {
  background: $picton-blue;
  color: $white;
}

@media only screen and (max-width: 550px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .wrapper {
    flex-direction: column-reverse;
    width: 100%;

    button {
      width: 100%;
      margin: 0;
    }
  }

  .search {
    margin: 10px 0;
    width: 100%;
  }
}

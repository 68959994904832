@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.form {
  top: 50%;
  max-width: 550px;
  width: 100%;

  @media only screen and (max-width: 1280px) {
    .auth-controls > button {
      margin-top: 24px;
    }
  }
}

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.button {
  @extend .btn-primary;
}

.input {
  @extend .form-input;
}

@import '../../assets/styles/colors';
@import "../../assets/styles/fontMixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.611vw 5.9vw;
  background: $home-footer;
  color: $white;
  font-style: normal;
  font-weight: normal;
  font-size: 1.111vw;
  line-height: 1.667vw;
  width: 100vw;
  min-height: 16.422vw;
  @include google-sans-regular;

  a {
    color: $white;
    &:hover {
      text-decoration: underline;
    }
  }
}

.info {
  display: flex;
  align-items: center;
  width: 33.333%;
}

.address {
  & > div {
    margin: 2px 0;
  }
}

.logo {
  height: 5.589vw;
  margin-right: 3.312vw;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.terms {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.333%;

  .termsContent {
    display: flex;
    flex-direction: column;
  }
}

.payments {
  display: flex;
}

.paymentIcon {
  margin-right: 0.625vw;
  fill: #656461;
  width: 2.967vw !important;
  height: 2.967vw;
}

.social {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .socialIcon {
    margin: 0 1.104vw;
    width: 2.967vw;
    height: 2.967vw;
    transition: .3s;
    &:hover {
      transform: translateY(-5px);
      filter: brightness(1.2);
    }
  }
}

.facebook,
.telegram,
.instagram {
  fill: $white;
  transition: fill 0.4s;
}

.white {
  color: $white;
  a {
    color: $white;
  }
}

.dark {
  color: $black;
  a {
    color: $black;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 1.389vw 0;
    font-size: 1.111vw;
    line-height: 1.667vw;
  }

  .info {
    width: 95vw;
    margin: 0.694vw auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    & > div {
      text-align: center;
      font-size: 3vw;
      line-height: 3.667vw;
    }

    .logo {
      height: 8.167vw;
      margin-bottom: 1vw;
      margin-right: 0;

    }
  }

  .address {
    font-size: 2vw;
    line-height: 2.667vw;
  }

  .terms {
    width: 95%;
    margin: 2vw auto;
    font-size: 3vw;
    line-height: 4.667vw;
  }

  .social {
    width: 95%;
    margin: 0.694vw auto;
    justify-content: center;

    a {
      margin: 0 1.556vw;
    }

    .socialIcon {
      width: 5.967vw;
      height: 5.967vw;
    }
  }

  .payments {
    margin-top: 2.967vw;
    justify-content: center;
  }

  .paymentIcon {
    width: 5.967vw !important;
    height: 5.967vw;
  }

  .right {
    flex-direction: column;
  }

  .terms {
    text-align: center;
  }

  .termsContent {
    a {
      font-size: 3vw;
      line-height: 4vw;
    }
  }
}

@import "../../../assets/styles/colors";
@import "../../../assets/styles/variables";
@import "../../../assets/styles/forms";

.contactForm {
  :global {
    .ant-form-item {
      margin: 0 !important;
      width: 100%;
    }

    .ant-col .ant-form-item-control {
      position: relative;
      background: $red;
    }

    .ant-form-item-control-input-content {
      flex: none;
      width: 100%;
    }

    .ant-form-item-explain {
      @include product-sans;
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 0.903vw;
      color: $red;
      display: flex;
      align-items: flex-end;
      text-align: left;
    }

    .ant-input {
      background: transparent !important;

      &:focus {
        -webkit-box-shadow: none;
      }
    }

    .ant-btn-primary {
      border: none;

      &:focus {
        border: none;
      }
    }
  }
}

.whiteForm {
  .input {
    color: $white;
    border-bottom: 3px solid $white;
  }
}

.button {
  @include open-sans;
  font-size: 0.83333333333vw;
  line-height: 2.153vw;
  font-weight: 700;
  font-style: normal;
  width: 13.8888888889vw;
  height: 4.16666666667vw;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-top: 5.06944444444vw;
  color: var(--font) !important;
  background-color: var(--background) !important;
}

.input {
  @include open-sans;
  width: 42.159vw;
  height: 3.333vw;
  background: transparent;
  border: none;
  margin-bottom: 1.4vw;
  font-size: 0.97222222222vw;
  -webkit-appearance: none;
  border-bottom: 0.208333333333333vw solid $black;
  padding: 0;

  &:hover {
    background: transparent;
    border-color: var(--primary-color);
  }

  &:focus {
    border-color: var(--primary-color);
    outline: none !important;
    box-shadow: none !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 0.903vw;
  }

  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 0.903vw;
  }

  &:-ms-input-placeholder { /* IE 10+ */
    font-size: 0.903vw;
  }

  &:-moz-placeholder { /* Firefox 18- */
    font-size: 0.903vw;
  }
}


@media only screen and (max-width: 760px) {
  .contactForm {
    :global {
      .ant-form-item-explain {
        font-size: 3.3vw !important;
        bottom: 2.4vw !important;
      }
    }
  }

  .input {
    width: 100%;
    height: 3em;
    font-size: 1.1em !important;
    margin-bottom: 8vw;
    border-top: none;
    color: $black;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-size: 3.703703703703704vw;
      line-height: 5.026455026455026vw;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      line-height: 5.026455026455026vw;
      font-size: 3.703703703703704vw;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      font-size: 3.703703703703704vw;
      line-height: 5.026455026455026vw;
    }

    &:-moz-placeholder { /* Firefox 18- */
      font-size: 3.703703703703704vw;
      line-height: 5.026455026455026vw;
    }
  }

  .button {
    margin-top: 13.227513227513228vw;
    min-width: 53.33vw;
    min-height: 16vw;
    padding: 5.866vw 14.4vw;

    span {
      font-size: 3.174603174603175vw;
      line-height: 4.322751322751323vw;
      letter-spacing: 0.2em;
    }
  }
}

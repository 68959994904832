@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/forms';

.container {
  position: relative;
  width: 100%;
  background: $white no-repeat center;
  background-size: cover;
  overflow: hidden;
  z-index: 0;
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4; // 3 will be used for elements

    width: 90%;
    max-width: 550px;
    background: $white;
    border-radius: 9.47px;
    margin: 0 auto;
  }

  i {
    position: absolute;
    z-index: 3;
    opacity: 0.7;
  }
}

.square {
  bottom: 200px;
  left: 480px;

  @media only screen and (max-width: 1280px) {
    left: 30%;
    bottom: 10%;
  }
}

.poly {
  top: 300px;
  right: 110px;

  @media only screen and (max-width: 1280px) {
    top: 10%;
    right: 10%;
  }
}

.angle-circle {
  bottom: -321px;
  right: -107px;
}

@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fontMixins.scss';

.successCertificatePage {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10vw;
}

.info {
  width: 44vw;
}

.thanksTitle {
  @include gotham-pro-black;
  margin-top: 4.33333vw;
  font-size: 3.472vw;
  line-height: 3.819vw;
  margin-bottom: 1.25vw;
  font-weight: 400;
  text-transform: uppercase;
  color: $black;
}

.thanksInfo {
  @include open-sans-light;
  color: $charcoal;
  font-weight: 300;
  font-size: 1.80555555556vw;
  line-height: 2.430vw;
}

.additionalInfo {
  @include open-sans-light;
  font-size: 1.11111111111vw;
  line-height: 1.736vw;
  margin: 0;
}

.button {
  @include open-sans;
  height: 4.16vw;
  font-size: 0.833vw;
  letter-spacing: 0.2vw;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.5277vw 1.944vw;
  line-height: 0.5vw;

  &:first-child {
    margin-right: 0.90vw;
  }

  border: 1px transparent;
}

.buttons {
  display: flex;
  margin-top: 3.472vw;
}

.whiteFonts {
  p, a, span, h3 {
    color: $white;
  }
}

@media screen and (max-width: 768px) {
  .successCertificatePage {
    padding: 0 8.465608465608466vw 26.455026455026455vw;
  }

  .buttons {
    margin-top: 50px;
    flex-wrap: wrap;
  }
  .info {
    width: 90%;
  }

  .thanksTitle {
    padding: 0;
    font-size: 9.788359788359788vw;
    width: 100%;
    line-height: 11.64021164021164vw;
    margin: 0 0 10.582010582010582vw;
  }

  .thanksInfo {
    font-size: 4.761904761904762vw;
    line-height: 6.613756613756614vw;
    margin-bottom: 5.291005291005291vw;
  }

  .additionalInfo {
    font-size: 4.232804232804233vw;
    line-height: 6.613756613756614vw;
    margin-bottom: 10.610079575596817vw;
  }

  .button {
    margin: 0;
    width: 100%;
    min-height: 16vw;

    &:first-child {
      margin-right: 0;
    }
    &:nth-child(1) {
      margin-bottom: 5vw;
    }

    a {
      font-size: 3.174603174603175vw;
      line-height: 4.322751322751323vw;
      letter-spacing: 0.2em;
    }
  }
}

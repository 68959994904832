@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';

.confirmEmailWrap {
  font-size: .9em;
  min-height: calc(100vh - 140px);
  align-items: center;
  justify-content: center;
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -80px;

  h3 {
    font-size: 36px;
    text-transform: uppercase;
  }

  p {
    font-size: 16px;

    a {
      color: $picton-blue;
    }
  }
}

.icon {
  font-size: 100px;
  margin-bottom: 20px;
}

@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/forms';

.hamburgerContainer{
  position: absolute;
  left: 3.611vw;
  width: 28px;
  height: 21px;
}

.hamburgerMain{
  position: fixed;
  width:25px;
  height: 4.167vw;
  z-index: 10;
  display: none;
  right: 3.611vw;

  input[type="checkbox"]:checked ~ .sidebarMenu {
    transform: translateX(-100vw);
  }

  input[type=checkbox] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
  }

  input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;

  }
  input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    top: 8px;
  }
  input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    top: -9px;
  }
}

.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  height: 21px;
  width: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: $home-dark;
}

.line {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
}

.horizontal, .diagonal.part1, .diagonal.part2 {
  @extend .line;
}

.sidebarMenu {
  position: absolute;
  width: 100vw;
  margin-top: 5.5vh;
  height: 100vh;
  transition: 250ms ease-in-out;
  z-index: 5;
  background: $home-bg-ligth;
  right: -103.611vw;
}
.sidebarMenuInner{
  padding: 4vh 12vw;
  margin: 0;
}

.icon {
  font-size: 25px;
  margin-right: 10px;
}

.sidebarMenuInner li {
  @include gotham-pro-regular;
  list-style: none;
  color: $home-dark;
  padding: 15px 15px 15px 0;
  cursor: pointer;
  font-size: 25px;
  display: flex;
  align-items: center;
  font-weight: bold;

  &:hover, &:hover a{
    color: $home-light-green;
  }

  a {
    display: flex;
    align-items: center;
  }

  svg {
    margin-top: -4px;
  }
}

.sidebarMenuBlack li{
  color: $black;
  &:hover, &:hover a{
    color: $white;
  }
}

.sideBarMenuWhite li{
  color: $white;
  &:hover, &:hover a{
    color: $black;
  }
}
.sidebarMenuInner li a{
  color: $home-dark;
  text-decoration: none;
  transition: 0s;
}
.whiteFonts{
  .spinner {
    background: $white !important;
  }

    .sidebarMenuInner {
      li {
        color: $white !important;
        &:hover, &:hover a {
          color: $black !important;
        }

    }
  }
}
@media only screen and (max-width: 768px) {
  .hamburgerMain{
    display: block;
  }
}

@media only screen and (max-width: 350px) {
  .sidebarMenuInner li{
    font-size: 20px;
  }
}


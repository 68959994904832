@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/forms';

// 8px    =>  0.556vw
// 9px    =>  0.625vw
// 10px   =>  0.694vw
// 11px   =>  0.764vw
// 12px   =>  0.833vw
// 13px   =>  0.903vw
// 14px   =>  0.972vw
// 15px   =>  1.042vw
// 16px   =>  1.111vw
// 17px   =>  1.181vw
// 18px   =>  1.250vw
// 19px   =>  1.319vw
// 20px   =>  1.389vw
// 21px   =>  1.458vw
// 22px   =>  1.528vw
// 23px   =>  1.597vw
// 24px   =>  1.667vw
// 25px   =>  1.736vw
// 26px   =>  1.806vw
// 27px   =>  1.875vw
// 28px   =>  1.944vw
// 29px   =>  2.014vw
// 30px   =>  2.083vw
// 31px   =>  2.153vw
// 32px   =>  2.222vw
// 33px   =>  2.292vw
// 34px   =>  2.361vw
// 35px   =>  2.431vw
// 36px   =>  2.500vw
// 37px   =>  2.569vw
// 38px   =>  2.639vw
// 39px   =>  2.708vw
// 40px   =>  2.778vw
// 41px   =>  2.847vw
// 42px   =>  2.917vw
// 43px   =>  2.986vw
// 44px   =>  3.056vw
// 45px   =>  3.125vw
// 46px   =>  3.194vw
// 47px   =>  3.264vw
// 48px   =>  3.333vw
// 49px   =>  3.403vw
// 50px   =>  3.472vw
// 51px   =>  3.542vw
// 52px   =>  3.611vw
// 53px   =>  3.681vw
// 54px   =>  3.750vw
// 55px   =>  3.819vw
// 56px   =>  3.889vw
// 57px   =>  3.958vw
// 58px   =>  4.028vw
// 59px   =>  4.097vw
// 60px   =>  4.167vw
// 61px   =>  4.236vw
// 62px   =>  4.306vw
// 63px   =>  4.375vw
// 64px   =>  4.444vw

.home {
  @include google-sans-regular;
  width: 100%;
  background-color: $home-bg-ligth;
  position: relative;
  overflow:hidden;

  :global {
    .ant-form-item-explain {
      text-align: left;
    }
    .ant-input .ant-form-item-has-error {
      border-color: $red-1 !important;
    }
  }

  section {
    padding: 3.472vw 0;
  }
  a {
    color: $white;
  }

  .waveImage {
    position: absolute;
    top: -22vw;
    right:-6.5vw;
    max-width: 60vw;
    z-index: 1;

  }

  .title {
    @include gotham-pro-black;
    font-style: normal;
    font-size: 3.125vw;
    line-height: 2.23958333vw;
    text-align: center;
    color: $home-dark;
  }

  .button {
    @include button;
    @include gotham-pro-bold;
    background: $home-light-green;
    border-radius: 0.556vw;
    border: none;
    color: $white;
    font-style: normal;
    font-weight: bold;
    font-size: 0.903vw;
    line-height: 0.7vw;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 1.736vw 2.222vw;
    cursor: pointer;
    outline: none;
    transition: 0.4s;
    opacity: 0.9;
    z-index:1;

    &:hover {
      background-image: linear-gradient(270deg, #0090b2 0%, #00e2ec 100%);
      opacity: 1;
    }
  }

  .buttonSecondary {
    @extend .button;
    background: $white;
    border: 1px solid $home-light-green;
    color: $home-light-green;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    outline: none;
    margin-left: 2.042vw;
    transition: 0.4s;
    &:hover {
      background-image: linear-gradient(
                      61.15deg,
                      #48b6bb 0%,
                      #48b6bb 0%,
                      #74eaef 100%
      );
      opacity: 0.7;
      color: $white;
    }
  }

  .buttonSmall {
    @include button;
    @include google-sans-regular;
    background: transparent ;
    border-radius: 0.556vw;
    color: $home-light-brown;
    border: 1px solid $home-light-brown;
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    padding: 0.556vw 1.111vw;
    cursor: pointer;
    outline: none;
    margin-right: 1.042vw;
    opacity: 0.9;
    transition: 0.4s;
    white-space: nowrap;
    &:hover {
      border: 1px solid $home-light-green;
      background-image: linear-gradient(270deg, #0090b2 0%, #00e2ec 100%);
      opacity: 1;
    }
  }

  .buttonSmallSecondary {
    @include button;
    @include google-sans-regular;
    background: $home-light-green;
    border-radius: 0.556vw;
    border: 1px solid $home-light-green;
    color: $white;
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    padding: 0.556vw 1.111vw;
    cursor: pointer;
    outline: none;
    margin-right: 1.042vw;
    transition: 0.4s;
    &:hover {
      background-image: linear-gradient(
        61.15deg,
        #48b6bb 0%,
        #48b6bb 0%,
        #74eaef 100%
      );
      opacity: 0.7;
      color: $white;
    }
  }
}

.header {
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.333vw 5.9vw;
  position: relative;
  z-index: 3;

  .logo {
    height: 4.167vw;
    min-height: 4.167vw;

    img {
      max-height: 100%;
      max-width: 100%;
      min-width:24px;
      min-height:24px;
    }
  }
  a{
    display: flex;
  }
}

.hero {
  @include gotham-pro-light;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 50px 0;
  position: relative;
  z-index: 1;

  .heroContent {
    width: 50%;
    padding-left: 5.9vw;
    position: relative;
    z-index: 5;

    .mainHeading {
      @include gotham-pro-black;
      font-style: normal;
      font-size: 3.472vw;
      line-height: 3.819vw;
      color: $home-dark;
    }

    .subHeading{
      font-style: normal;
      font-size: 1.389vw;
      color: $home-grey-second;
      max-width: 37.5vw;
      margin-bottom: 5.25vw;
      line-height: 2.083vw;
    }
  }

  .heroImage {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 100%;
    }
  }
  .homeBox{
    position: relative;
    min-width: 60vw;
    right:6vw;
    z-index: 2;
  }
}

.ourGoal {
  text-align: center;
  color: $home-grey;
  max-width: 57.5vw;
  margin: 0 auto;
  padding-top: 4.5vw;

  .subTitle {
    margin: 0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.389vw;
    line-height: 2.1vw;
    margin-top: 2.333vw;
    max-width: 52vw;
  }

  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    margin-top: 1.111vw;
  }

  .ourGoalImage {
    margin: 3.472vw auto;
    width: 44.791vw;

    img {
      width: 100%;
    }
  }
}

.howItWorks {
  max-width: 57.5vw;
  margin: 0 auto;
  .step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.222vw 0;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  .image {
    width: 50%;
    img {
      width: 100%;
    }
  }

  .content {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .number {
    font-style: normal;
    font-weight: bold;
    font-size: 4.444vw;
    line-height: 5.626vw;
    margin-right: 3.333vw;
    color: $home-light-green;
  }

  .stepTitle {
    @include google-sans-bold;
    font-style: normal;
    font-size: 1.389vw;
    line-height: 1.736vw;
    color: $home-dark;
    margin-bottom: 1.111vw;
  }

  .stepText {
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    color: $home-grey;
  }
}

.opportunities {
  max-width: 83.333vw;
  margin: 0 auto;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image {
    width: 50%;

    img {
      width: 100%;
    }
  }

  .content {
    width: 50%;
    margin-top: 2em;

    .listItem {
      @include google-sans-bold;
      display: flex;
      font-style: normal;
      font-weight: bold;
      font-size: 1.389vw;
      line-height: 1.736vw;
      color: $home-grey;
      margin-bottom: 1em;

      p {
        margin-bottom: 0;
      }

      img {
        margin-right: 2.431vw;
        height: 1.5vw;
        margin-top: .3vw;
      }
    }

    .check {
      margin-right: 2.431vw;
    }
  }
}

.platform {
  width: 100%;
  position: relative;
  z-index:1;

  .waveMiddle {
    position: absolute;
    top: -23%;
    right: 0;
    z-index: 1;
    width: 50%;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    z-index: 2;
    max-width: 73.055vw;
    margin: 0 auto;
    margin-top: 3.125vw;
  }

  .card {
    background: $white;
    border-radius: 0.556vw;
    width: 31.6%;
    margin-bottom: 1.8%;
    display: flex;
    flex-direction: column;
    padding: 2.361vw;
  }

  .cardImage {
    margin-bottom: 1.667vw;
    height: 4em;
  }

  .cardText {
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    text-align: center;
  }
}

.ourClients {
  text-align: center;
  position: relative;
  z-index: 2;
}

.contact {
  width: 100%;
  position: relative;
  z-index: 1;

  .waveBottom {
    position: absolute;
    right:0;
    top: 18%;
    width: 78%;
    z-index: 1;
  }

  .formWrapper {
    max-width: 49.097vw;
    margin: 16vw auto 14vw auto;
    background: $white;
    border-radius: 0.556vw;
    text-align: center;
    padding: 3.333vw;
    position: relative;
    z-index: 2;
  }

  .formSubTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    text-align: center;
    color: $home-grey;
    padding: 1.389vw 0 3.472vw 0;
  }

  .inputWrapper {
    :global {
      .ant-form-item-control {
        margin-bottom: 1.222vw;
      }
    }
  }

  .input {
    @include google-sans-regular;
    background: $white;
    border: 1px solid $home-light-green;
    box-sizing: border-box;
    border-radius: 0.556vw;
    width: 100%;
    display: block;
    font-size: 0.833vw;
    line-height: 1.111vw;
    letter-spacing: 0.025em;
    padding: 1.111vw;
    resize: none;
  }
}

.benefits {
  @include gotham-pro-black;
  text-align: center;
  color: $home-grey;
  max-width: 49.5vw;
  margin: 0 auto;
  padding-top: 4.5vw;
  .benefitCloudRight{
    position: absolute;
    width: 18vw;
    right:0;
    top:16%;
  }
  .benefitCloudLeft{
    position: absolute;
    width: 12vw;
    left:0;
    top:18%;
  }

  .subTitle {
    margin: 0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.389vw;
    line-height: 2.1vw;
    margin-top: 2.333vw;
    max-width: 52vw;
  }

  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.667vw;
    margin-top: 1.111vw;
  }

  .ourGoalImage {
    margin: 3.472vw auto;
    width: 44.791vw;

    img {
      width: 100%;
    }
  }
}

.pricingButtons {
  @include open-sans;
  align-items: center;
  position: absolute;
  top:8vw;
  left:-37vw;
  color:$home-dark;
  width: 28vw;
  z-index: 6;
  transition: 0.2s ease;
}


.selfPricing {
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  background-color: $white;
  padding: 1.389vw;
  border-radius: 0.556vw;
  width:100%;
  cursor: pointer;

}
.pricingButtonsContainer{
  cursor: pointer;
  width: 30.6944444444vw;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.2s ease;
  height:6.5vw;
  box-shadow: 2vw 1.3vw 3.5vw  -2.8vw #000000;
  &:first-child{
    top:-0.5vw;
    z-index:6;
    right:6vw;
  }
  &:hover .selfPricing{

  }
  &:hover .pricingBadge{
    background: rgba(104, 220, 226, 0.1);
    border: 2px solid $home-light-green;
    &:after{
      content:"+";
      position: absolute;
      font-size: 1.389vw;
      right: 2.3vw;
      color:$home-light-green;
    }
  }
}
.pricingText{
  @include open-sans;
  position: relative;
  width: 36vw;
  top:10vh;
  left:52%;
  font-size:1.302vw;
  z-index: 5;
  .title{
    margin-bottom: 1.389vw;
    line-height: 2.98611111111vw;
  }
  .title, .subHeading, .info{
    text-align: left;
  }
  .info{
    margin: 0.69vw 0 4.51388888889vw 0 !important;
  }

  .subHeading{
    font-size: 1.736vw;
    line-height: 2.431vw;
    color: black;
  }

}
.pricingLogo{
  height:4.167vw;
  width:4.0128vw;
  margin-bottom: 4.16666666667vw;
}
.commissionImg{
  width: 2.8vw;
  height:2.6vw;
  margin: 0.833333vw;
  padding: 6px;
  border-radius: 8px;
  background: $home-light-green;
}
.moneyWorldImg{
  width: 2.8vw;
  height:2.6vw;
  margin: 0.833333vw;
  padding: 6px;
  border-radius: 8px;

}
.pricingBadge{
  height: 5vw;
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  border: 2px solid rgba($home-light-green, 0.5);
}

.benefitItem {
  @include google-sans-bold;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 1.389vw;
  color: $home-grey;
  align-items: flex-start;
  margin-bottom: 1em;

  &:first-child {
    margin-top: 2em;
  }
  p {
    margin-bottom: 0;
    text-align: left;
  }
  img {
    margin-right: 2.431vw;
    height: 1.5vw;
    margin-top: .3vw;
  }
}

.circlesContainer{
  position: absolute;
  width: 10vw;
  top:60%;
  left:-7%;
  margin-right: -5vw;
}
.circle{
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  top: 50%;
  right:50%;
}
.circle1{
  @extend .circle;
  width: 33.5416666667vw;
  height: 33.5416666667vw;
  background: $home-light-green;
  margin: -16.7708333333vw -16.7708333333vw 0 0;
  z-index:5;
}
.circle2{
  @extend .circle;
  margin: -25vw -25vw 0 0;
  width: 50vw;
  height: 50vw;
  background: linear-gradient(30deg, #FFF1BF 9.1%, #74EAEF 100%);
}
.circle3{
  @extend .circle;
  width: 64.6527777778vw;
  height: 64.6527777778vw;
  margin: -32.3263888889vw -32.3263888889vw 0 0;
  border:2px solid $home-light-green;
}
.circle4{
  @extend .circle;
  border: 2px solid rgba(81, 192, 198, 0.2);
  width: 81.5277777778vw;
  height:81.5277777778vw;
  margin: -40.7638888889vw -40.7638888889vw 0 0;
}

.pricing {
  position: relative;
  color: $home-grey;
  margin: 0 auto;
  padding-top: 4.5vw;
  .highlighted{
    color: $home-light-green;
  }

  .subTitle {
    margin: 0 auto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.389vw;
    line-height: 2.1vw;
    margin-top: 2.333vw;
    max-width: 52vw;
  }

  .info {
    font-style: normal;
    font-weight: normal;
    font-size: 1.111vw;
    line-height: 1.736vw;
    margin-top: 1.111vw;
    margin-bottom: 5vw;
  }
}

.demoLink {
  color: $picton-blue-rgba !important;

  &:hover {
    text-decoration: underline;
  }
}
.navContainer {
  height:100%;
  right:20vw;
  position: absolute;
  a {
    color: $home-dark;
  }
}

.contactNumber{
  font-size: 3vw;
  align-items: center;
  display: none;
}

.navUl {
  margin: auto;
  display: flex;
  list-style: none;
  height:100%;
}

.navEl {
  color: $home-dark;
  font-size: 1.2vw;
  margin-right: 3vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  &:hover{
    color: $home-light-green;
  }
  &:last-child{
    min-width:13vw;
  }
  a{
    transition: none;
    &:hover{
      color: $home-light-green;
    }
  }

}

.userButtons{
  display: flex;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: $home-dark;
}

@media only screen and (max-width: 1024px) {
  .benefits {
    max-width: 55vw;
  }
}

@media only screen and (max-width: 768px) {
  .circlesContainer{
    display: none;
  }
  .pricingLogo{
    width:58px;
    height:60px;
  }
  .pricingText{
    width:100%;
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    .subHeading{
      font-size:16px;
      line-height: 25px;
      text-align: center;
      margin-bottom: 15px;
    }
    .info{
      text-align: center;
    }
  }
  .waveImage{
    display: none;
  }

  .contactNumber {
    font-size: 20px;
  }
  .header {
    background: $home-bg-ligth;
    position: fixed;

    .contactNumber{
      display: flex;
    }
    .buttonSmall, .buttonSmallSecondary{
      display: none;
    }
    .navUl{
      display: none;
    }
    .logo {
      height: 13.167vw;
      min-height: 13.167vw;
    }
  }
  .home {
    width: 100%;
    section {
      padding: 35px 0;
    }
    .title {
      font-size: 20px;
      line-height: 26px;
    }
    .button,
    .buttonSmall,
    .buttonSmallSecondary {
      border-radius: 8px;
      font-size: 12px;
      line-height: 13px;
      padding: 16px 15px;
    }
  }

  .hero {
    flex-direction: column;
    padding: 50px 0;
    .homeBox{
      top:0;
      right:14%;
      width:80vw;
      height:100%;
    }

    .heroContent {
      margin-top: 10vh;
      width: 90%;
      padding-left: 0;
      text-align: left;
      max-width: 500px;

      .mainHeading {
        font-size: 22px;
        line-height: 33px;
      }

      .subHeading {
        font-size: 15px;
        line-height: 25px;
        max-width: unset;
        margin-bottom: 20px;
      }
    }

    .heroImage {
      overflow: hidden;
      height:100%;
      width:90%;
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-top: 22px;
    }
  }

  .ourGoal {
    width: 95%;
    max-width: unset;
    margin: 0 auto;

    .subTitle {
      font-size: 16px;
      margin-top: 25px;
      padding: 0 5%;
      line-height: 25px;
      margin-bottom: 34px;
      max-width: 80vw;
    }

    .info {
      font-size: 14px;
      margin-top: 15px;
      line-height: 25px;
      padding: 0 5%;
      margin-bottom: 50px;
    }

    .ourGoalImage {
      margin: 15px auto;
      width: 90%;
    }
  }

  .howItWorks {
    max-width: unset;
    width: 95%;
    .step {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      flex-direction: column !important;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }
    }

    .image {
      width: 80%;
      img {
        width: 100%;
      }
    }

    .content {
      width: 95%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .number {
      font-size: 32px;
      line-height: 36px;
      margin-right: 10px;
    }

    .stepTitle {
      font-size: 16px;
      line-height: 22px;
    }

    .stepText {
      font-size: 13px;
      line-height: 22px;
    }
  }

  .opportunities {
    max-width: unset;
    width: 95%;
    .wrapper {
      flex-direction: column-reverse;
    }

    .image {
      width: 80%;
    }

    .content {
      width: 100%;
      margin: 25px auto;

      .listItem {
        display: flex;
        padding:0 3%;
        font-size: 14px;
        line-height: 22px;
        color: $home-grey;

        img {
          height: 2.5vw;
          margin-top: 1.1vw;
        }
      }

      .check {
        margin-right: 2.431vw;
      }
    }
  }

  .platform {
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      position: relative;
      z-index: 2;
      max-width: unset;
      margin: 0 auto;
      margin-top: 3.333vw;
    }

    .card {
      border-radius: 8px;
      width: 95%;
      padding: 20px;
      max-width: 300px;
      margin-bottom: 25px;
    }

    .cardImage {
      margin-bottom: 1.667vw;
    }

    .cardText {
      text-align: left;
      margin-top: 10px;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .contact {
    width: 100%;
    position: relative;

    .waveBottom {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 30%;
      z-index: 1;
    }

    .formWrapper {
      max-width: unset;
      margin: 0 auto;
      width: 90%;
      border-radius: 8px;
      text-align: center;
      padding: 15px;
    }

    .formSubTitle {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $home-grey;
      padding: 1.111vw;
    }

    .inputWrapper {
      :global {
        .ant-form-item-control {
          margin-bottom: 1.222vw;
        }

        .ant-form-item-explain {
          font-size: 10px;
        }
      }
    }

    .input {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.025em;
      padding: 10px;
    }
  }

  .benefits {
    max-width: 95vw;
    ul{
      padding: 0 3%;
    }

    .ourGoalImage {
      margin: 15px auto;
      width: 90%;
    }
  }

  .benefitItem {
    margin-bottom: 3vw;
    p {
      font-size: 16px;
      text-align: left;
    }

    img {
      height: 2.5vw;
      margin-top: 1.1vw;
    }
  }


  .pricingButtons {
    position: static;
    margin-top: 20px;
    width:90%;
    max-width: 750px;
    justify-content: space-between;
    z-index: 2;
    height:250px;
    img{
      height: 45px;
      width: 45px;
      margin-right: 20px;
    }

    .pricingButtonsContainer {
      position: static;
      width:100%;
      height:90px;
      &:first-child{
        margin-bottom:30px;
      }
      .selfPricing{
        font-size:16px;
      }
      .pricingBadge{
        padding:15px;
        height:90px;
        &:after{
          right: 31px; //sum of paddings
          font-size: 24px;

        }
      }
    }
  }

  .paymentPricing {
    margin-bottom: 25px;
  }

  .pricing {
    width: 95%;
    max-width: unset;
    margin: 0 auto;
    .subHeading{
      text-align: left;
    }
    .subTitle {
      font-size: 16px;
      margin-top: 25px;
      padding: 0 5%;
      line-height: 25px;
      margin-bottom: 24px;
      max-width: 90vw;
    }

    .info {
      text-align: left;
      font-size: 14px;
      margin-top: 15px;
      line-height: 25px;
      margin-bottom: 50px;
    }
  }
  .navContainer{
    position: static;
    margin-left: -2vw;
  }
}

@media only screen and (max-width: 350px){

  .contactNumber{
    font-size: 16px;
    margin-left:-16px;
  }

  .pricingButtons {
    .pricingButtonsContainer {
      .pricingBadge{
        font-size:14px;
        padding:6px;
        padding-right:20px;
        img{
          margin-right:4vw;
          height: 30px;
          width: 30px;
        }
        &:after{
          right: 14px; //sum of paddings
          font-size: 24px;

        }
      }
    }
  }

}

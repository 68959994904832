@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.container {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.text {
  @include helvetica-neue;
  font-size: 16px;
  text-align: center;
  max-width: 60vw;
  margin: 0 auto;
}

.column {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.minusIndent {
  margin-top: -100px;
}

.icon {
  font-size: 100px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 1rem;
}

.formWrapper {
  width: 90%;
  max-width: 700px;
  margin: 30px auto;
}

.button {
  @extend .btn-primary;
}

.input {
  @extend .form-input;
}

@media screen and (max-width: 550px) {
  .text {
    max-width: 90vw;
  }
}

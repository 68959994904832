@import '../../assets/styles/colors';
@import "../../assets/styles/fontMixins";

.header {
  display: flex;
  justify-content: space-between;
  background: $home-footer;
  align-items: center;
  width: 100%;
  z-index: 3;

  &.home {
    background-color: transparent;
    z-index: 4;

    .menu {
      border-bottom: none;
    }
  }

  @media only screen and (max-width: 1280px) {
    padding: 0;
  }
}

.logo {
  background-image: url("../../assets/images/logo_footer.svg");
  width: 52px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

.menu {
  display: flex;
  align-items: center;
  line-height: 64px;
  background: transparent;
  overflow: hidden;
  width: 100%;
  margin-left: 20px;
}

.right {
  justify-content: flex-end;
}

.icon {
  font-size: 22PX;
  margin-right: 10px;
  color: $white;
  cursor: pointer;

  &:hover {
    color: $home-grey;
  }
}

.menuIcon {
  margin-right: 5px;
}

:global {
  .ant-dropdown {
    @include product-sans;
  }
  .ant-menu.ant-menu-dark {
    background-color: transparent;
  }

  .ant-layout-header {
    padding: 0 20px;
    height: 70px;
  }

  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
    @include product-sans;
    position: relative;
    text-transform: uppercase;
    font-size: 14px;
    padding: 0;
    margin: 0 20px;

    a {
      line-height: 2;
      color: $white;
    }

    &.ant-menu-item-selected {
      background-color: transparent;
      & > a {
        color: $black;
        font-weight: 800;
      }
    }

    &:hover {
      box-sizing: border-box;
      & > a {
        color: $black;
        font-weight: 800;
      }
    }

    @media only screen and (max-width: 1280px) {
      margin: 0 10px;
      font-size: 12px;
    }
  }
  .ant-menu-submenu-popup {
      a{
        color:black !important;
      }
    .ant-menu-submenu-popup {
      background: black;
    }
    }
  }
@media only screen and (max-width: 375px){
  .menu {
    margin-left: 1vw;
  }
  :global{
    .ant-menu-dark.ant-menu-horizontal > .ant-menu-item{
      margin: 0 1.3vw;
    }
  }
}

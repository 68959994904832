@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/fontMixins.scss';

.infoBlock {
  padding: 0;
  margin: 0 auto;
  text-align: center;
  width: 72.788vw;
  margin-bottom: 3vw;
  background-color: $home-bg-ligth;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.sectionTitle {
  margin-top: 1.656vw;
  font-size: 2.695313vw;
  line-height: 3.45vw;
  font-weight: 800;
  text-align: center;
  max-width: 57.408vw;
}

.logo {
  margin-top: 5.451vw;
  height: 9.522vw;
  object-fit: contain;
  max-width: 30vw;
}

.description {
  max-width: 57.408vw;
  font-size: 1.104vw;
  line-height: 1.656vw;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  color: $home-grey;
  white-space: pre-wrap;
}

.plane {
  margin-top: 4.209vw;
  height: 7.383vw;
  width: 8.28vw;
}

.alabaster {
  color: $alabaster !important;
}

.languages {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 1.4vw;
}

.language {
  margin-right: 0.4vw;
  color: $home-grey;
  font-size: 1vw;

  &:hover {
    color: $black;
    text-decoration: underline;
  }
}


.white {
  color: $white !important;

  .language {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.active {
  text-decoration: underline;
}

@media only screen and (max-width: 550px) {
  .infoBlock {
    width: 100%;
    margin-bottom: 30px;
  }

  .languages {
    display: block;
    position: relative;
    right: 0;
    top: 1.4vw;
    width: 100%;
    text-align: right;
    padding: 0 4vw;
  }

  .language {
    margin-right: 1vw;
    font-size: 3.5vw;
  }

  .logo {
    height: 12.865vh;
    margin: 1.2em 0;
    max-width: 60vw;
  }

  .sectionTitle {
    font-size: $mobileTitleSize;
    line-height: $mobileTextLineHeight;
    max-width: $mobileContentWidth;
  }

  .description {
    max-width: $mobileContentWidth;
    font-size: $mobileTextSize;
    line-height: $mobileTextLineHeight;
  }

  .plane {
    height: 14.383vw;
    width: 13.28vw;
    margin: 2em 0;
  }
}

@import '../../assets/styles/colors';
@import '../../assets/styles/fontMixins';
@import '../../assets/styles/forms';

.banner{
  position: fixed;
  display: flex;
  background: $home-grey-second;
  bottom: 0;
  min-height: 9vw;
  z-index: 50;
  color: $white;
  align-items: center;
  opacity: 0.95;
  padding: 2vw 3vw;
  a{
    margin-left: 0.6vw;
    &:hover{
      text-decoration: underline;
    }
  }
}
.bannerText{
  font-size: 1vw;
  margin: 0;
  margin-right: 3vw;
}

.bannerButton {
  @include button;
  @include gotham-pro-bold;
  background: $home-light-green;
  border-radius: 0.556vw;
  border: none;
  color: $white;
  font-style: normal;
  font-weight: bold;
  font-size: 0.903vw;
  min-width: 5vw;
  height: 2.3vw;
  line-height: 0.3vw;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  transition: 0.4s;
  opacity: 0.9;
  z-index:1;

  &:hover {
    background-image: linear-gradient(270deg, #0090b2 0%, #00e2ec 100%);
    opacity: 1;
  }
}

a.link {
  color: $home-light-green;
}

@media screen and (max-width: 768px) {
.banner{
  min-height: 120px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
  .bannerText{
    font-size: 13px;
    line-height: 16px;
  }

  .bannerButton{
    height: 25px;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 6px;
    width: 100%;
    margin-top: 20px;
  }
}

@mixin code-pro {
  font-family: 'CodeProLC';
}

@mixin gotha-pro-med {
  font-family: 'GothaProMed';
}

@mixin myriad-pro-regular {
  font-family: 'MyriadProRegular';
}

@mixin helvetica {
  font-family: 'Helvetica';
}

@mixin helvetica-bold {
  font-family: 'Helvetica Bold';
}

@mixin helvetica-neue {
  font-family: 'HelveticaNeue';
}

@mixin helvetica-neue-light {
  font-family: 'HelveticaNeue Light';
}

@mixin helvetica-neue-medium {
  font-family: 'HelveticaNeue Medium';
}

@mixin helvetica-neue-bold {
  font-family: 'HelveticaNeue Bold';
}

@mixin gotham-pro-bold {
  font-family: 'GothamProBold';
}

@mixin gotham-pro-regular {
  font-family: 'GothamProRegular';
}

@mixin gotham-pro-light {
  font-family: 'GothamProLight';
}

@mixin gotham-pro-black {
  font-family: 'GothamProBlack';
}

@mixin google-sans-regular {
  font-family: 'GoogleSansRegular';
}

@mixin google-sans-bold {
  font-family: 'GoogleSansBold';
}

@mixin open-sans {
  font-family: 'Open Sans', sans-serif;
}
@mixin open-sans-light {
  font-family: "OpenSansLight";
}

@mixin product-sans {
  font-family: 'Product Sans Medium', sans-serif;
}

@mixin product-sans-light {
  font-family: 'Product Sans Light', sans-serif;
}



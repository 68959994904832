@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/fontMixins.scss';

.landingPage {
  @include product-sans;
  z-index: 12;
  font-size: 16px;
  width: 100%;
  height: 100%;
  color: $black;
  background-color: $home-bg-ligth;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    color: $black;
  }
}

.zeroIndent {
  margin-top: 0;
}

.certificatesBlock {
  margin: 2.691vw auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  max-width: 57.408vw;
  font-size: 2.15625vw;
  line-height: 2.76vw;
  font-weight: 800;
  text-align: center;
}

.contactBlock {
  margin-top: 10.764vw;
  margin-bottom: 10.488vw;
  width: 52.992vw;
  height: 37.467vw;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.556vw;

  h4 {
    margin-top: 3.312vw;
    margin-bottom: 0;
  }

  p {
    max-width: 29.67vw;
    text-align: center;
    margin-top: 0.552vw;
    font-size: 1.111vw;
    line-height: 1.667vw;
    margin-bottom: 3.333vw;
  }
}

.withAdditionalText{
  margin-bottom: 3vw;
}

.additionalBlock {
  margin-bottom: 16vw;
}

.additionalText {
  width: 70vw;
  margin-top: 4vw;
  max-width: 57.408vw;
  font-size: 1.104vw;
  line-height: 1.656vw;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  color: #5e5e5e;
  white-space: pre-wrap;
}

.white {
  color: $white;
}

@media only screen and (max-width: 550px) {
  .landingPage {
    margin-top: 0;
  }

  .additionalText {
    max-width: 98%;
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .title {
    max-width:  none;
    font-size: $mobileSubTitleSize;
    line-height: $mobileTextLineHeight;
  }

  .contactBlock {
    width: 90vw;
    height: auto;
    border-radius: 8px;

    p {
      font-size: $mobileTextSize;
      line-height: $mobileTextLineHeight;
      margin-top: 2vw;
      max-width: 80vw;
    }
  }

  .certificatesBlock {
    width: $mobileContentWidth;
  }
}

@import "../../assets/styles/fontMixins";

.container {
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);

  h3 {
    color: rgba(0, 0, 0, 0.65);
  }

  p {
    font-size: 16px;
  }
}

@import './colors';
@import './fontMixins';

.btn-primary {
  width: 100%;
  margin-top: 20px;
  :global {
    .ant-btn {
      width: 100%;
      margin: 5px auto;
      @include product-sans;
      &:hover {
        color: $home-grey;
        border-color: $home-grey;
      }
    }
    .ant-btn.ant-btn-primary {
      color: $white;
      background-color: $picton-blue-rgba;
      border-color: $picton-blue-rgba;
      transition: .2s;
      &:hover {
        background-color: $picton-blue-rgba-light;
        border-color: $picton-blue-rgba-light;
      }
    }
  }
}

.form-input {
  width: 100%;
  display: flex;
  flex-direction: column;

  input {
    @include product-sans;
    border-color: $mercury;
  }

  :global {
    .ant-form-item-label {
      text-align: left;
      @include product-sans;
    }
  }
}

:global {
  .ant-form-item-control-input-content {
    @include product-sans;
  }
}

@mixin button {
  font-weight: 500;
  cursor: hand;
  text-align: center;
  position: relative;
  overflow: hidden!important;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  z-index:10;


  &:hover{
    color: $white;
  }
  &::before {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background-image: linear-gradient(270deg, #0090b2 0%, #00e2ec 100%);
    position: absolute;
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    left: -10%;
    opacity: 1;
    top: 0;
    z-index: -12;
    -moz-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    -o-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    -webkit-transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow:2px 0 14px rgba(0,0,0,.6);
  }

  &::after {
    content: '';
    width: 0;
    height: 100%;
    display: block;
    background: #80ffd3;
    position: absolute;
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: -15;
    -webkit-transition: all .94s cubic-bezier(.2,.95,.57,.99);
    -moz-transition: all .4s cubic-bezier(.2,.95,.57,.99);
    -o-transition: all .4s cubic-bezier(.2,.95,.57,.99);
    transition: all .4s cubic-bezier(.2,.95,.57,.99);
    box-shadow: 2px 0 14px rgba(0,0,0,.6);
  }
  &:hover::before, &:hover::before{
    opacity:1;
    width: 116%;
  }
  &:hover::after, &:hover::after{
    opacity:1;
    width: 120%;
  }

}

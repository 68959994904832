@import '../../assets/styles/colors';

.landingContainer {
  width: 100%;
  min-height: calc(100vh - 92px);
}
.newLandingContainer{
  width: 100%;
}

.container {
  color: $black;
  background: $home-bg-ligth;
  margin: 0 auto;
  min-height: calc(100vh - 92px);
  box-shadow: 0 2px 24px 6px rgba(108, 109, 110, 0.07);
  position: relative;
}
.newContainer{
  @extend .container;
  min-height: 0;
}

@media only screen and (max-width: 550px) {
  .container {
    min-height: calc(100vh - 20px);
  }
  .newContainer{
    min-height: 0;
  }

}

@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.container {
  width: 90%;
  text-align: center;
  margin: 2rem 0 3em;
}

.text {
  @include product-sans;
  font-size: 18px;
  text-align: center;
  max-width: 60vw;
  margin: 0 auto;

  a {
    color: $picton-blue;

    &:hover {
      text-decoration: underline;
    }
  }
}

.column {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 100px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 1rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;

  button {
    transform: scale(1.5);
  }
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    @extend .text;
    color: $picton-blue;
    margin: 5px 0;
    font-size: 18px;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 800px) {
  .text {
    max-width: 90vw;
  }
}

.garlandMain {
  width: 100%;
  position: relative;
  padding-top: 4%;
  display: none;

  .garlandCointainer {
    width: 100%;
    position: absolute;
    top: -6.5vw;
    right: 0;

    @media screen and (max-width: 550px) {
      top: -8vw;
    }

    img {
      height: auto;
      width: 90%;
      display: block;
      margin: 0 auto;

      @media screen and (max-width: 550px) {
        width: 100%;
      }

    }
  }
}

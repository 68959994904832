@import "../../../assets/styles/colors";

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;

  :global {
    .ant-form-item {
      margin-bottom: 0 !important;

      & > div {
        & > label {
          white-space: nowrap;
        }
      }
    }
    .ant-form-item-explain {
      font-size: 11px;
      position: absolute;
      bottom: -26px;
    }
  }
}

.warning {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.hint {
  color: $home-light-green;
  cursor: pointer;
}

.column {
  margin-bottom: 24px;

  & > div {
    display: flex;
    flex-direction: column;

    & > div {
      text-align: left;
    }
  }

  textarea {
    resize: none;
  }
}

.button {
  margin-top: 32px;
  margin-left: 10px;
}

.input {
  text-transform: uppercase;

  &::placeholder {
    text-transform: initial;
  }
}

@media only screen and (max-width: 550px) {
  .row {
    flex-direction: column;

    :global {
      .ant-form-item {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .button {
    width: 100%;
    margin-left: 0;
  }
}

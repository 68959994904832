@import "../../assets/styles/colors";
@import "../../assets/styles/fontMixins";

.root {
  min-height: 100vh;
  height: 100%;
  background: $white;
  @include product-sans;
}
.header {
  background: $elephant;
}
.menu {
  line-height: 64px;
  background: $elephant;
}

.logout {
  float: right;
}

.content {
  display: flex;
  justify-content: center;
  align-items: stretch;
  font-size: 2em;
  min-height: calc(100vh - 70px);
}

.preview {
  border: 4px solid $red;
  position: relative;
  z-index: 1000;
  width: 100vw;
  height: 100%;
  pointer-events: none;
  cursor: not-allowed;

  &:before {
    content: 'Попередній перегляд';
    display: block;
    position: absolute;
    width: 200px;
    background: $red;
    left: 50%;
    margin-left: -100px;
    z-index: 10;
    text-align: center;
    padding-top: 19px;
    color: $white;
    font-size: 16px;
    height: 72px;
    bottom: 0;
  }
}

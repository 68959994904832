@font-face {
  font-family: 'CodeProLC';
  src: url('../fonts/CodeProLC.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GothaProMed';
  src: url('../fonts/GothaProMed.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadProRegular';
  src: url('../fonts/MyriadPro-Regular.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica-01.ttf?pokwqw') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Bold';
  src: url('../fonts/Helvetica-Bold-02.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue-01.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Bold';
  src: url('../fonts/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Light';
  src: url('../fonts/HelveticaNeue-Light-08.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Medium';
  src: url('../fonts/HelveticaNeue-Medium-11.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans Thin';
  src: url('../fonts/ProductSans-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans Light';
  src: url('../fonts/ProductSans-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans Medium';
  src: url('../fonts/ProductSans-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans Bold';
  src: url('../fonts/ProductSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "GothamProBold";
  src: url("../fonts/GothamProBold/GothamProBold.eot");
  src: url("../fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/GothamProBold/GothamProBold.woff") format("woff"),
  url("../fonts/GothamProBold/GothamProBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GothamProBold";
  src: url("../fonts/GothamProBold/GothamProBold.eot");
  src: url("../fonts/GothamProBold/GothamProBold.eot?#iefix")format("embedded-opentype"),
  url("../fonts/GothamProBold/GothamProBold.woff") format("woff"),
  url("../fonts/GothamProBold/GothamProBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;

}
@font-face {
  font-family: "GothamProRegular";
  src: url("../fonts/GothamProRegular/GothamProRegular.eot");
  src: url("../fonts/GothamProRegular/GothamProRegular.eot?#iefix")format("embedded-opentype"),
  url("../fonts/GothamProRegular/GothamProRegular.woff") format("woff"),
  url("../fonts/GothamProRegular/GothamProRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "GothamProLight";
  src: url("../fonts/GothamProLight/GothamProLight.eot");
  src: url("../fonts/GothamProLight/GothamProLight.eot?#iefix")format("embedded-opentype"),
  url("../fonts/GothamProLight/GothamProLight.woff") format("woff"),
  url("../fonts/GothamProLight/GothamProLight.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "GothamProBlack";
  src: url("../fonts/GothamProBlack/GothamProBlack.eot");
  src: url("../fonts/GothamProBlack/GothamProBlack.eot?#iefix")format("embedded-opentype"),
  url("../fonts/GothamProBlack/GothamProBlack.woff") format("woff"),
  url("../fonts/GothamProBlack/GothamProBlack.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "GoogleSansRegular";
  src: url("../fonts/GoogleSans-Regular.eot")format("embedded-opentype"),
  url("../fonts/GoogleSans-Regular.woff") format("woff"),
  url("../fonts/GoogleSans-Regular.woff2") format("woff");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "GoogleSansBold";
  src: url("../fonts/GoogleSans-Bold.eot")format("embedded-opentype"),
  url("../fonts/GoogleSans-Bold.woff") format("woff"),
  url("../fonts/GoogleSans-Bold.woff2") format("woff");
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "OpenSansLight";
  src: url('../fonts/OpenSans-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
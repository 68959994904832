@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/fontMixins.scss';

.arrow {
  width: 5.555vw;
  height: 5.555vw;
  border-radius: 50%;
  border: 1px solid $black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border-color: var(--color) !important;

    svg {
      path {
        fill: var(--color) !important;
      }
    }
  }
}

.arrowRight {
  @extend .arrow;
  transform: rotate(180deg);
}

.arrowLeft {
  @extend .arrow;
  margin-right: 1.388vw;
}

.arrowsContainer {
  display: flex;
  margin-bottom: 3.26vw;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 3.3333vw;

  :global {
    .whiteDots {
      li button:before {
        color: white !important;
      }
    }
  }
}

.cardTitle {
  @include gotham-pro-bold;
  font-size: 1.38vw;

}

.priceContainer {
  @include open-sans;
  display: flex;
  text-transform: uppercase;
}

.priceRoot {
  font-size: 1.52777777778vw;
  line-height: 2.08vw;
  margin: 0;
}

.price {
  @extend .priceRoot;
  font-weight: 600;
  margin-right: 0.83vw;
  color: $landing-grey;
}

.discount {
  @extend .priceRoot;
  color: $landing-light-grey;
  text-decoration: line-through;
}

.description {
  @include open-sans;
  font-size: 1.11111111111vw;
  line-height: 1.73611111111vw;
  width: 24.8611111111vw;
  font-weight: 400;
  color: $charcoal;
}

.mainContainer {
  margin-top: 3.47vw;
  display: flex;
}

.sliderContainer {
  margin-left: 3.19444444444vw;
  width: 43.8888888889vw;
  height: 38.4722222222vw;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
  }

  :global {
    .slick-list {
      height: 100%;

      & > div {
        height: 100%;
      }
    }

    .slick-slide {
      & > div {
        height: 100%;

        & > div {
          height: 100%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.imageWrapper {
  margin: 0;
  width: 100%;
  height: 100%;
}

.sliderImage {
  width: 43.8888888889vw;
  height: 38.4722222222vw;
  object-fit: cover;
  margin-bottom: 2vw;

  svg {
    width: 100%;
    height: 100%;
  }
}

.mainTitle {
  @include gotham-pro-black;
  font-size: 3.47222222222vw;
  line-height: 3.81944444444vw;
  width: 45vw;
  margin: 0 auto;
  color: $black;
}

.placeholder {
  @extend .sliderImage;
  object-fit: contain;
}

.whiteFonts {
  color: $white;

  .arrow {
    border: 1px solid $white;
  }

  h3,
  .description {
    color: $white;
  }
}

.mobile {
  display: none;
}

.desktop {
  display: flex;
}

@media screen and (max-width: 760px) {
  .mainTitle {
    margin: 0;
    font-size: 9.788359788359788vw;
    width: 100%;
    padding: 0 8.465608465608466vw;
    line-height: 11.64021164021164vw;
    color: $black;
  }

  .content {
    margin: 0;
    padding: 0 8.53333333333vw;
  }

  .sliderContainer {
    width: 100vw;
    height: 100%;
    margin: 0;

    & > div {
      width: 100%;
      height: 86.772486772486772vw;
    }

    .arrowsContainer {
      height: initial;
      padding: 0 8.53333333333vw;
    }

    :global {
      .slick-dots {
        ul {
          padding: 0;
        }
      }
    }
  }

  .arrowLeft {
    margin-right: 15px;
  }

  .cardTitle {
    font-size: 5.291005291005291vw;
    line-height: 7.142857142857143vw;
    margin-top: 14.285714285714286vw;
    margin-bottom: 3.174603174603175vw;
  }

  .priceContainer {
    margin: 0 0 7.671957671957672vw;
  }

  .price {
    font-size: 4.761904761904762vw;
    line-height: 6.613756613756614vw;
  }

  .description {
    width: 100%;
    font-size: 4.210526315789474vw;
    line-height: 6.613756613756614vw;
  }

  .arrow {
    height: 14.021164021164021vw;
    width: 14.021164021164021vw;
  }

  .arrowsContainer {
    margin-bottom: 4.674603174603175vw;
  }

  .mainContainer {
    margin-top: 10.582010582010582vw;
    flex-direction: column;
  }

  .sliderImage {
    width: 100vw;
  }

  .mobile {
    height: initial !important;
    display: flex;
  }

  .desktop {
    display: none;
  }
}

@import '../../assets/styles/colors.scss';

.infoBlock {
  min-height: 20.424vw;
}

.container {
  flex-direction: row;
  align-items: center;
  margin-bottom: 4vw;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 27.186vw;
  margin-left: 4.416vw;
  p {
    cursor: initial !important;
  }
  p::before {
    display: none;
  }
  a {
    display: block;
  }
}

.tier {
  width: 33.81vw;
  height: 19.734vw;;
  margin: 0 2.484vw;
  background: linear-gradient(112.13deg, rgba(199, 160, 246, 0.05) 15.53%, rgba(255, 87, 189, 0.05) 88.64%), $white;
  border-radius: 0.556vw;
}

.info {
  width: 29.483vw;
}

@media only screen and (max-width: 550px) {
  .CertificateCheckoutDetails {
    width: 85%;
  }

  .container {
    flex-direction: column;
    align-items: center;
  }

  .info {
    // height: 25.283vw;
    // height: 36.283vw;
    height: 41vw;
    width: 75vw;

    a {
      font-size: 3vw;
    }
  }

  .tier {
    width: 100%;
    //height: 50vw;
    height: 61vw;
    margin: 0 2.484vw;
    border-radius: 0.556vw;
  }

  .description {
    max-width: 100%;
    margin: 3vw 0 4vw;

    p {
      font-size: 1.111em;
      line-height: 1.5em;
      text-align: left;
    }
  }
}

@import '../../../assets/styles/fontMixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px;
  padding: 10px;
}
.header {
  @include product-sans;
  margin-bottom: -9px;
}

@import '../../assets/styles/fontMixins';
@import '../../assets/styles/colors';
@import '../../assets/styles/forms';

.icon {
  color: rgba(0, 0, 0, 0.25);
}

.button {
  @extend .btn-primary;
}

.input {
  @extend .form-input;
}

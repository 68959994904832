@import '../../../assets/styles/colors';
@import '../../../assets/styles/fontMixins';

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  @extend .column;
  width: 100%;
  padding: 10px;

  :global {
    .ant-form-item-explain {
      font-size: 11px !important;
    }
  }
}

.block {
  min-height: 100px;
  color: $black;
  background: $white;
  padding: 10px;
  margin: 10px;
  border: 1px solid #f0f0f0;
}

.textarea {
  resize: none;
}

.title {
  @include product-sans;
  font-size: 18px;
}

.subtitle {
  font-size: 14px;
  margin-right: 10px;
}

.subheader {
  font-size: 12px;
  margin-right: 10px;
  display: block;
}

.select {
  min-width: 160px !important;
  width: 100%;
}

.topIndent {
  margin-top: 20px;
}

.footerPreview {
  width: 100% !important;
  height: auto;
  margin: 0;
  margin-top: 15px;
  position: relative;

  & > img {
    max-height: 100%;
    max-width: 100%;
  }
}

.color {
  min-width: 240px !important;
}

.notification {
  margin: 10px 0;
  color: #515151;
  font-size: 11px;
}

.footer {
  display: flex;
  flex-direction: column;
}

.toggle {
  width: 30px;
  margin-right: 5px;
}

.button {
  width: 100%;
}

@media screen and (min-width: 651px) {
  .container {
    width: 50%;
  }
}

@media screen and (min-width: 901px) {
  .container {
    width: 35%;
  }
  .title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1151px) {
  .footerPreview {
    margin-left: 10px;
    width: 170px !important;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;

    & > img {
      max-height: 90%;
      max-width: 90%;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
  }

  .button {
    width: 200px;
  }
}

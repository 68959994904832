@import '../../../assets/styles/colors';
@import '../../../assets/styles/global';

.container {
  margin: 10px;
  padding: 10px;
}

.withData {}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 10px;
  }
}

.edit {
  color: $picton-blue;
}

.remove {
  color: $red;
}

.typeIcon {
  cursor: pointer;
  color: $picton-blue;

  span {
    margin-right: 10px;

    &:last-child {
      margin: 0;
    }
  }
}

.tag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid $black;
  cursor: pointer;
}

.description {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
}

.pagination {
  margin-top: 20px;
}

.detection {
  margin-right: 10px;
}

.popover {
  display: flex;
  flex-direction: column;
}

.collapse {
  width: 250px;
  border: none;
  background: transparent;
  max-height: 400px;
  overflow: auto;
}

.email {
  color: $picton-blue;
}

.link {
  @extend .email;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.payment {
  margin-top: 10px;
}

.active {
  color: $picton-blue;
}

.notActive {
  color: $red;
}

.secondary {
  color: $home-grey;
}

.createPaymentModal {
  text-align: center;
}

.margin {
  margin-top: 10px;
}

.manual {
  @extend .margin;
  color: $home-footer;
}

.row {
  display: flex;
  color: $red;
  min-width: 60px;

  svg {
    margin-right: 4px;
    margin-top: 3px;
  }
}
.expired{
  color: $red;
}

@media screen and (max-width: 1200px) {
  .container {
    .withData {
      :global {
        @include table;
        .ant-table-thead {
          display: none;
        }

        td.ant-table-cell {
          border: none;
          border-bottom: 1px solid #eee;
          position: relative !important;
          padding-left: 50% !important;
        }

        .ant-table-cell:before {
          position: absolute;
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }

        .ant-table-cell:nth-of-type(1):before { content: "Назва"; }
        .ant-table-cell:nth-of-type(2):before { content: "Номер"; }
        .ant-table-cell:nth-of-type(3):before { content: "Дата створення"; }
        .ant-table-cell:nth-of-type(4):before { content: "Дані клієнта"; }
        .ant-table-cell:nth-of-type(5):before { content: "Тип"; }
        .ant-table-cell:nth-of-type(6):before { content: "Ціна (грн)"; }
        .ant-table-cell:nth-of-type(7):before { content: "Поточна вартість (грн)"; }
        .ant-table-cell:nth-of-type(8):before { content: "Статус"; }
      }
    }
  }
}

.paymentWidget {
  width: 40vw;
  margin: 0 auto;
  margin-top: 10vw;
  min-width: 380px;
}

@media screen and (max-width: 768px) {
  .paymentWidget {
    width: 100%;
  }
}


@import '../../assets/styles/colors';

.wrapper {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  background: $white;
  width: 100%;
}

.row {
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 0.972vw;
    padding: 0.694vw;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      cursor: pointer;
      color: $black;
    }
  }
}

.about {
  max-height: 0;
  height: auto;
  transition: max-height 1s;
  overflow: auto;
  font-size: 1.111vw;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;

  .aboutContainer {
    padding: 0.3em;
    display: block;
  }
}

.visible {
  max-height:500px;
}

.white {
  color: $white !important;
}

@media only screen and (max-width: 550px) {

  .wrapper {
    position: static;
    background: $white;
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: center;

    span {
      font-size: 1em;
      padding: 0.694vw;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);

      &:hover {
        cursor: pointer;
        color: $black;
      }
    }
  }

  .about {
    transition: max-height 0.25s ease-in;
    overflow: hidden;
    font-size: 1em;
    border-top: 1px solid #d9d9d9;
  }
}

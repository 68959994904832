@import '../../../assets/styles/colors';
@import '../../../assets/styles/fontMixins';
@import '../../../assets/styles/global';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px;

  :global {
    .ant-form-item {
      min-width: 160px;
    }

    .ant-table-cell {
      padding: 8px 2px;
    }

    .ant-form-item-with-help {
      margin-bottom: 0;
    }

    .ant-form-item-explain {
      font-size: 11px !important;
    }
  }
}

.removeButton {}
.editButton {}

.removeIcon {
  transition: all 0.4s;

  &:hover {
    color: $red;
  }
}

.notification {
  margin: 0 0 10px;
  color: #515151;
  font-size: 11px;
}

.editIcon {
  margin-bottom: -2px;
  transition: all 0.4s;

  &:hover {
    color: $home-dark;
  }
}

.urlButton {
  margin-bottom: 24px;
}

.title {
  @include product-sans;

  font-size: 18px;
}

.indicator {
  width: 15px;
  height: 15px;
  display: block;
  margin-right: 7px;
  border: 1px solid $cerulean;
  border-radius: 3px;
  cursor: pointer;
}

.block {
  width: 100%;
  color: $black;
  background: $white;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid $alabaster;
}

.form {
  @extend .block;
  display: flex;
  flex-direction: column;
  min-height: 145px;
}

.half {
  display: flex;
  width: 60%;
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  padding: 7px;
}

.leftIndent {
  margin-left: 5px;
  cursor: pointer;
}

.rightIndent {
  margin-right: 5px;
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;

  div {
    min-width: 0 !important;
    margin-bottom: 0 !important;
  }
}

.columnField {
  @extend .field;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  & > div {
    width: 100%;
    margin-right: 10px!important;
  }
}

.textarea {
  resize: none;
}

.column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  & > div {
    width: 100%;
    margin-right: 10px!important;
  }
}

.span {
  cursor: pointer;
  margin-right: 10px;
  font-size: 15px;

  &:hover {
    color: #666666;
  }
}

.button {
  align-self: center;
  max-width: 300px;
}

.icon {
  margin-right: 5px;
}

.hidden {
  display: none;
}

div.social {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.preview {
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-size: 17px;
  margin-bottom: 24px;
}

.links {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.color {
  margin-top: 0;
  margin-bottom: 24px;
}

.date {
  min-width: 160px !important;
  width: 100%;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed !important;
  pointer-events: none;
}

.main {
  display: flex;
  align-items: center;
  margin-right: 10px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 140px;
  }
}

.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 140px;
}

.dates {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 180px;
}

.description {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.greyOut {
  @extend .disabled;
  background-color: #f5f5f5;
}

.urlWrapper {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 30px;
}

.urlInput {
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid;
  margin-bottom: -2px;

  &:focus{
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.error {
  position: absolute;
  color: $red;
  bottom: 0;
}

.urlPlaceholder {
  font-weight: bold;
}
.toggle{
  margin-top: 3vw;
  font-size:15px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin: 0 3vw;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $home-grey;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $home-light-green;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}


@media screen and (min-width: 901px) {
  .container {
    .removeButton,
    .editButton {
      display: none;
    }
    .removeIcon,
    .editIcon {
      display: block;
    }
  }

}

@media screen and (max-width: 1200px) {
  .container {
    .description {
      width: initial;
      overflow: initial;
      white-space: initial;
    }
    .removeButton,
    .editButton {
      display: block;
      width: 100%;
    }
    .removeIcon,
    .editIcon {
      display: none;
    }
    :global {
      @include table;
      .ant-table-thead {
        display: none;
      }

      td.ant-table-cell {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative !important;
        padding-left: 50% !important;
      }

      .ant-table-cell:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
      }

      .ant-table-cell:nth-of-type(1):before { content: "Назва та колір"; }
      .ant-table-cell:nth-of-type(2):before { content: "Умови використання"; }
      .ant-table-cell:nth-of-type(3):before { content: "Ціна та номінал"; }
      .ant-table-cell:nth-of-type(4):before { content: "Активація і термін дії"; }
    }
  }
}


@media screen and (min-width: 1151px) {
  .column {
    width: 49%;
  }
  .social {
    width: 45%;
    align-self: flex-end;
  }
  .form {
    justify-content: space-between;
  }
  .row {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .block {
    margin: 10px;
  }

  .title {
    font-size: 22px;
  }

  .item {
    font-size: 17px;
    padding: 10px;
  }

  .span {
    font-size: 17px;
  }

  .container {
    :global {
      .ant-form-item {
        min-width: 160px;
        margin-bottom: 24px;
      }

      .ant-form-item-with-help {
        margin-bottom: 0;
      }

      .ant-form-item-explain {
        font-size: 11px !important;
      }
    }
  }

  .color {
    margin-bottom: initial;
  }
}
@media screen and (max-width: 370px){
  .preview{
    flex-direction: column;
  }
}

@import '../../../assets/styles/fontMixins';
@import '../../../assets/styles/colors';

.modal{
  :global{
    .ant-modal-close{
      top: 13px;
    }
  }
}

.body{
  display: flex;
  flex-direction: column;
}

.block{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
}

.emoji {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.textarea {
  padding-right: 30px;
}

@import '../../assets/styles/colors.scss';
@import '../../assets/styles/variables.scss';
@import '../../assets/styles/fontMixins.scss';

.balancePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vw;
}

.mainTitle {
  @include gotham-pro-black;
  text-align: start;
  color: $black;
  width: 50%;
  margin-bottom: 1.3vw;
  font-size: 3.47222222222vw;
  line-height: 3.81vw;
  text-transform: uppercase;
}

.info {
  width: 50%;
  display: flex;
  margin-bottom: 2.77vw;
}

.buttonWrapper {
  widtH: 50%;
}

.button {
  @include open-sans;
  height: 4.16vw;
  font-size: 0.833vw;
  letter-spacing: 0.2vw;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.5277vw 1.944vw;
  line-height: 0.5vw;
  width: 49%;
  border: none;
}

.infoText {
  @include open-sans-light;
  font-size: 1.38vw;
  line-height: 1.73vw;
  color: $charcoal;
  font-weight: 300;
}

.infoBlock {
  margin-right: 1.52vw;
}

.infoTable {
  @include open-sans;
  min-width: 24vw;
  padding: 1.1vw 7.08vw;
  border: 3px solid #E4E4E4;
  font-weight: 700;
  font-size: 0.90vw;
  line-height: 1.73vw;
  letter-spacing: 0.15vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteFonts {
  p, h3, div {
    color: $white !important;
  }
}

@media screen and (max-width: 768px) {
  .balancePage {
    padding: 0 8.53333333333vw;
  }

  .mainTitle {
    font-size: 10.904761904761905vw;
    line-height: 11.375661375661376vw;
    width: 100%;
    margin-bottom: 7.936507936507937vw;
  }

  .info, .buttonWrapper {
    width: 100%;
  }

  .info {
    flex-direction: column;
  }

  .infoText {
    font-size: 4.232804232804233vw;
    line-height: 6.613756613756614vw;
    margin-bottom: 2vw;
  }

  .infoBlock {
    margin: 3vw 0;
  }

  .infoTable {
    width: 100%;
    min-height: 16vw;
    font-size: 3.174603174603175vw;
    line-height: 4.322751322751323vw;
  }

  .button {
    min-width: 53.33vw;
    min-height: 16vw;
    width: 100%;
    margin-bottom: 10.610079575596817vw;

    a {
      font-size: 3.174603174603175vw;
      line-height: 4.322751322751323vw;
      letter-spacing: 0.2em;
    }
  }
}

@media screen and (max-width: 550px) {
  .balancePage {
    padding-top: 160px;
  }
}
